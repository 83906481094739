body {
    background-color: #f0f0f0;
}

.login .form-control {
    background-color: #fffcc8 !important;
}

.jqx-button {
    background-color: #0079c1 !important;
}

.arcgis .jqx-button  {
    background-color: #fff !important;
    border-color: #0079c1 !important;
    color: #0079c1 !important;
}
.s-alert-box {
    min-width: 250px;
}

.s-alert-close::before, .s-alert-close::after {
    width: 2px;
}

/* ********************************* */
/* Hide arrows on input type numbers */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* ********************************* */
#navBar {
	height: 245px;
	margin-bottom: 25px;
}

#topo1 {
	background: #ffffff !important;
	width: 100%;
    height: 175px;
    left: 0;
    top: 0;
	position: absolute;
}
#topo2 {
	background: #e6e6e6;
	text-align: center;
	width: 100%;
    height: 70px;
	padding: 15px;
    left: 0;
    top: 175px;
	position: absolute;
	overflow: hidden;
}
@media screen and (max-width: 710px) {
    #topo2 h2 {
        font-size: 16px;
    }
}

#logo {
	background: url(/static/media/logo_geosgb_navbar.92f41b1e.png) no-repeat left top;
	height: 205px;
	position: relative;
}
#logo p {
	color: #fff;
	font-size: 18px;
	padding: 50px 133px;
	position: relative;
}
#menuContainer {
	width: 200px;
}
#nomeUsuarioWrap{
	white-space: auto;
	display: flex;
	justify-content: center;
	font-size: .6rem;
	width: 200px;
}
#tituloWrap > h3 {
	margin-bottom: 0;
}


.navLinks{
	margin-top: 110px;
}

.link-white{
	color: #fff;
}

.link-white:hover{
	color: #fff;
}

#topo2 h2 {
	flex: 2 1;
}

#navBar-completo {
	width: 100%;
	position: static !important;
}

#topo1-completo {
	background: #ffffff !important;
	width: 100%;
	height: 55px;
	position: static !important;
}

#topo1-completo #logo {
	margin: 0 0 0 20%;
	max-height: 100%;
}

#topo1-completo p {
	margin: 0;
}
.fixed{
	position: fixed !important;
	top: 0;
}

#topo2-completo {
	display: flex;
	position: static;
	justify-content: space-between;
	width: 100%;
	min-height: 40px;
	padding: 0.4rem 1rem;
	margin-top: 5px;
	align-items: center;
	background: #e6e6e6;
	text-align: center;
}

#topo2-completo h2 {
	margin: 0 auto;
}

/* exibição inicial */
.logos-cabecalho {
	flex: 1 1;
	width: 100%;
	height: 100%;
	justify-content: space-around;
	align-items: center;
	display: flex;
}

.logo-container {
	flex: 1 1;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 20px;
	max-width: 27%;
	overflow: hidden;
}

.logo-container img {
	max-height: 150px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

/* 1 - Serviço Geológico do Brasil CPRM */
.logo-cprm img {
	max-height: 137px;
}

/* 2 - GEO SGB Sistemas Geocientíficos */
.logo-sgb {
	/* background: url(../../assets/img/logo_geosgb_navbar.png) no-repeat left top; */
	/* background: url(../../assets/img/logo_geosbg_sgih.png) no-repeat left top; */
	width: 220px;
		height: 205px;
		margin: 1% 20%;
		position: relative;
	max-width: 25%;
}

.logo-sgb p {
	color: #fff;
	font-size: 18px;
	font-size: 1.1vw;
	padding: 50px 0 50px 133px;
	position: relative;
	max-width: 25%;
}

/* 3 - ANA Agência Nacional de Águas e Saneamento Básico */
.logo-ana img {
	max-height: 137px;
}

/* 4 - Rede Hidrometeorológica Nacional */
.logo-rnh img {
	width: 280px;
	max-height: 280px;
}

/* 5 - SGIH Sistema de Gerenciamento de Informações Hidrométricas */
.logo-sgih {
	margin: 0;
}

.logo-sgih img {
	max-height: 55px;
}

#cprm{

}
#sgih{
	max-height: 70px;
}
#sgb{
	max-height: 90px;
}


.logo-cabecalho-img {
	margin-left: 30px !important;
	margin-right: 30px !important;

	/* max-width: 90%;
    max-height: 90%; */
}
.menuLateral {
    position: absolute;
    left: 0;
    z-index: 10000;
}

.nome{
    color : #082767;
    width: 100%;
    text-align: center;
    padding: 0px;
}
.cargo{
    color: #082767;
    width: 100%;
    text-align: center;
    padding: 0px;
    font-style: italic;
    font-size: small;
    margin-bottom: 10px;
}

.modal-title {
    color : #082767;
    width: 100%;
    text-align: center;
    padding: 0px;
}

.h4, h4 {
    font-size: 1.99rem;
}

.jqx-menu-item {
    width: 200px !important;
}

.jqx-menu-dropdown {
    width: 200px !important;
}

.caixa_lov {
    border: 1px solid #082767;
    margin: 0 2px;
    background: #F0F0F0;
}




.footer-logo-container { 
	display: -webkit-box;
	margin-left: 10%;
	width: 40%;
}
.logo-footer-img {
	max-width: 100%;
	width: auto;
    height: auto;
}

.footer-container {
	margin: 0 20px; 
	max-width: 25%;
	overflow: hidden;
}

.footer{
	display: flex;
	justify-content: center;
	max-height: 7%;
	background: #ffffff !important;
}


.jqx-notification-icon-warning {
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAACl0lEQVRIiY2WzUtVURTF1744cBASVCIOQiJCRBo0sAYNotKiQeOIaNB/UEQ0atYoQhxUSBBE0ccfUAhOIojCkAaBCBY0KzPpQ0oiW78G7mvP57vvuuFyH2+vs/c6i33WuaE2YXtnRIxIOiZpEOiOiELSF0kzkiYlTUh6FxHtSv0PQECP7RvAAvXxFbgL9AEb6m1oa3tE0rikvmS1Iuk5MBURHyUVknYA+yQdiojOJPZJ0vmiKB61Y3/G9rJtbC8B14DeNvjttq/YXrQN8Bu4sGEnKcth4CeA7TlgCCgadtZluxfoBbY2rR0A3qRkf4DT65qk5u+TxZzt3S2ku2r7ez63W+R7genc/fy6GsBYKYvtAxVyXEsMtu9VYPptLyTRh4CK1PhURCgi7kTEVIXkRWLUZiRnJY1m/oSk/kLSUUndkpxJV7Bbe7caR0ll85vAL0ldkk4Wko5k/lVEfKiiFhGuKtyE+xYRE0lkuJA0kLkqacrYjERlvEzcQKFVeQQstluxGYkasN/yZ9fanNexiojaws21AJXGJWBbDStvVqKGg/ijQ6uuuE/SUA2xaeB+MnxRg92fuBkBZ/Pw/LXdV8OsA+ioY58+hu1LSm+Zzz/GGv2nMWzvBV4Db4GDFcVl+3J60hIwUC4ezQZLQEursH07MQBPKhrsabCKx2tkgW7gfUrV0uyAi6UXAddb5Htsl2a3AKwzOwEjtpdze3PAkO2iAdOZ98U521ua1vYD06Vd2z7TcqSBs8Byg1ybvXBKWf40XzgbBho4DoxL2pmHa0XSM6161WJiurQ6iocjohNQRHyWdF7Sg7bnxHZ5edwCFqkJ219t37W9q5Usla0S3AeMRMSwpEFJPcn2i6RZYFLS04io/Gz5BxDS/CKS/bl+AAAAAElFTkSuQmCC)!important;
}
.jqx-notification-close-button-warning {
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAQAAAADpb+tAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfmDAkTCQxyJRr/AAAAlUlEQVQI1y3PMU7DQABE0cHrwkg+KUUSybRQOEdwOEjgVlAlu4kUN4/CmfI1XxOjxawXEb3ZyRiL1c2kiGLSrJY+v3lkzDEv+cpbjnlNy18UOw3Vj4pmr0R09i62Xex1soUG5yefDbJxMalPrt4Vid7kjqtvV9xN+pg1NAeDg4abY5ys6hbS2alWpxgtPpXny+LDYvwH3aKqXFOnmxIAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMTItMDlUMTk6MDk6MDQrMDA6MDD8pHhcAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTEyLTA5VDE5OjA5OjA0KzAwOjAwjfnA4AAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMi0xMi0wOVQxOTowOToxMiswMDowMHWW1JsAAAAASUVORK5CYII=)!important;
}

.sub-header-reports {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ced4da;
    background: #e6e6e6;
    font-size: 13px;
    grid-gap: 3px;
    gap: 3px;
}

.sub-header-register {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ced4da;
    background: #e6e6e6;
    font-size: 13px;
    grid-gap: 3px;
    gap: 3px;
    margin-bottom: 16px;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    grid-gap: 3px;
    gap: 3px;
    background: #e6e6e6;
}

.text {
    font-weight: bold;
}
.jqx-tabs {
    overflow: visible !important;
    overflow: initial !important;
}

.jqx-tabs-headerWrapper {
    overflow: hidden !important;
    height: inherit !important;
}

/* .jqx-tabs-headerWrapper, .jqx-tabs-title-container {
    height: inherit !important;
}

.jqx-tabs-title-container {
    display: flex;
    width: 100% !important;
    flex-wrap: wrap;
    left: 0;
}

.jqx-tabs-title {
    float: none;
    position: initial;
} */

/* Completely hide visible=false tabs */
.esconder-abas-desativadas > .jqx-tabs-headerWrapper > .jqx-tabs-title-container > .jqx-tabs-title.jqx-tabs-title-disable {
    display: none;
}
.jqx-tabs-content {
    height: auto !important;
    height: initial !important;
}

.jqx-tabs-content-element {
    overflow: visible !important;
    overflow: initial !important;
}

.select-search {
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

.select-search.is-disabled {
    opacity: 0.5;
}

.select-search__value {
    position: relative;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 5px);
    right: 19px;
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search__input {
    display: block;
    width: 100%;
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    font-size: 14px;
    text-align: left;
    line-height: 36px;

    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.select-search__input::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.select-search__input:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.select-search__input::placeholder {
    color: #6c757d;
    opacity: 1;
}

.select-search__options {
    padding: 0;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

.select-search--multiple .select-search__input {
    cursor: auto;
    cursor: initial;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search__input:hover {
    border-color: #ced4da;
}

.select-search__input:not([readonly]):focus {
    cursor: auto;
    cursor: initial;
}

.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    height: 180px;
    overflow: auto;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__options {
    list-style: none;
}

.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

.select-search__option {
    display: block;
    /* height: 36px; */
    height: auto;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #ced4da;
    color: black;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: #e2e8ef;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #d6dbe2;
    color: black;
}

.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

.select-search input:disabled {
    color: #495057;
    background-color: #e9ecef;
}
label {
    display: block !important;
}

.react-datepicker-popper {
    z-index: 20 !important;
}

.react-datepicker-wrapper, .react-datepicker__input-container input {
    width: 100%;
}

input.dateTimeField[readonly] {
    background-color: #e9ecef;
    color: #495057;
}
input.dateTimeField[disabled] {
    background-color: #e9ecef;
    color: rgb(73, 80, 87);
}
input.dateTimeField {
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.withoutArrows {
    -moz-appearance: textfield;
}

.withoutArrows::-webkit-outer-spin-button,
.withoutArrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.CheckSetField_legend__1dmhc {
    font-size: 13px;
    width: auto;
    margin-left: 7px;
    margin-bottom: .4rem;
    padding: 0 4px;
}

.CheckSetField_fieldSet__1joY7 {
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: 5px 5px .65rem;
}


.file-grid__file {
    width: 185px;
    height: 215px;
    position: relative;

    padding: 5px;
    cursor: auto;
}

.file-grid__preview {
    width: 100%;
    height: 140px;
    border-radius: 3px;
    margin-bottom: 9px;
    display: flex;
    /*align-items: center;*/
    flex-direction: column;
    position: relative;
}

.file-grid__preview--uploading::before, .file-grid__preview--has-error::before {
    content: ' ';
    width: 100%;
    height: 140px;
    display: block;
    position: absolute;
    z-index: 1000;
    border-radius: 3px;
}

.file-grid__preview--uploading::before {
    background-color: rgba(0, 0, 0, .7);
}

.file-grid__preview--has-error::before {
    background-color: rgba(250, 0, 0, .7);
}

.file-grid__preview--has-error .file-grid__preview-icon {
    z-index: 2000;
}

.file-grid__preview--has-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-grid__preview-icon {
    font-size: 4rem;
}

.file-grid__preview--pdf {
    background-color: #e25149;
}

.file-grid__preview--word {
    background-color: #005996;
}

.file-grid__preview--file {
    background-color: #b3b3b3;
}

.file-grid__preview--zip {
    background-color: #967213;
}

.file-grid__preview i {
    color: white
}

.file-grid__preview img {
    object-fit: cover;
}

.file-grid__infoAndActions {
    display: flex;
}

.file-grid__info {
    flex-grow: 1;
    flex-shrink: 1;
    word-wrap: anywhere;
    overflow-wrap: anywhere;
}

.file-grid__actions {
    flex-grow: 0;
    flex-shrink: 0;
}

.file-grid__icon {
    padding: 3px;
    cursor: pointer;
}

.file-grid__icon--exclude::after {
    content: "❌";
}

.file-grid__icon--download {
    color: #3663ea;
}

.file-grid__file-name {
    margin-bottom: 4px;
}

.file-grid__file-size {
    font-size: 11px;
    color: grey;
}

.file-grid__file-error {
    font-size: 11px;
    color: crimson;
}

/* spinner */
.file-grid__spinner {
    display: inline-block;
    width: 80px;
    height: 80px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
}
.file-grid__spinner div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.file-grid__spinner div:nth-child(1) {
    left: 8px;
    animation: file-grid__spinner1 0.6s infinite;
}
.file-grid__spinner div:nth-child(2) {
    left: 8px;
    animation: file-grid__spinner2 0.6s infinite;
}
.file-grid__spinner div:nth-child(3) {
    left: 32px;
    animation: file-grid__spinner2 0.6s infinite;
}
.file-grid__spinner div:nth-child(4) {
    left: 56px;
    animation: file-grid__spinner3 0.6s infinite;
}
@keyframes file-grid__spinner1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes file-grid__spinner3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes file-grid__spinner2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

/* este CSS não é exatamente responsivo, mas responsividade não é requisito neste caso */

.file-grid {
    background-color: #ebebeb;
    border: 2px dashed grey;
    border-radius: 4px;
}

.file-grid__drop-zone {
    padding: 15px 0;
    cursor: pointer;
}

.file-grid__files {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1em;
    gap: 1em;
    padding: 0 15px;
}

.file-grid__text {
    font-size: 12px;
    margin: 0;
}

.file-grid__instructions p {
    text-align: center;
    margin: 0;
}

.file-grid__instructions + .file-grid__files {
    margin-top: 15px;
}

.file-grid__files + .file-grid__instructions {
    /*margin-top: 15px;*/
}

.jqx-input-label-sgih {
    display: none !important;
}

.jqx-disable-select-sgih {
    color: #000 !important;
}

.jqx-dropdownlist-content-disabled-sgih {
    background-color: #e9ecef;
}

.jqx-dropdownlist-sgih {
    background-color: #fff;
    font-size: 14px;
    color: #6c757d;
    margin-right: -10px;
}

.jqx-fill-state-pressed-sgih {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.jqx-fill-state-disabled-sgih {
    color: #000;
    background-color: #e9ecef !important;
}

.jqx-dropdownlist-content-sgih span:before {
    content: "   ";
    white-space: pre;
}

.jqx-dropdownlist-content-disabled-sgih span:before {
    content: "   ";
    white-space: pre;
}
.jqx-grid > div:nth-child(1)
> div:nth-child(1)
> div:nth-child(1)
> div:nth-child(1) {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
}
.tooltip-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-filter: inherit;
            filter: inherit;
}
.hide-text {
    color: transparent !important;
}
.jqx-grid {
    margin-bottom: 1rem !important;
}

.totalizers label.form-label {
    height: 40px;
    display: flex !important;
    flex-direction: column-reverse;
}

.buttonAndTooltip {
    display: flex;
    /* align-items: center; */
    flex-direction: column-reverse;
}

.buttonAndTooltip .jqx-loader {
    top: unset !important;
}

.tip, .option{
    padding-left: unset !important;
}

.tip {
    align-items: end !important;
}

.loading-container {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    overflow: hidden;
    outline: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999 !important;
}

.loading-container .loading-box {
    display: flex;
    position: relative;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: 136px;
    padding: 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:  rgba(255, 255, 255, 0.8);
    border-radius: 2rem;
}

.loading-container .loading-box div.jqx-widget.jqx-loader {
    display: flex !important;
    position: relative !important;
    margin: unset !important;
    border: none !important;
    background: none !important;
}

.loading-container .loading-box .loading-info {
    display: flex;
    position: relative;
    margin-top: 0.75rem;
    font-size: 15px;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.gerenciamentoRecibo [role="button"]{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center; 
}
    
  

:root { 
    --jqx-accent-color-material: rgba(158, 168, 168, 1);
    --jqx-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-background-color-material: rgba(255, 255, 255, 1);
    --jqx-header-text-color-material: rgba(0, 0, 0, 0.87);
    --jqx-header-background-color-material: rgba(255, 255, 255, 1);
    --jqx-border-color-material: rgba(227, 227, 227, 1);
    --jqx-border-radius-material: 500em;
    --jqx-font-family-material: ;
    --jqx-font-size-material: 14px;
    --jqx-hovered-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-hovered-state-background-color-material: rgba(158, 168, 168, 1);
    --jqx-hovered-state-content-text-color-material: rgba(51, 51, 51, 1);
    --jqx-hovered-state-content-background-color-material: rgba(245, 245, 245, 1);
    --jqx-focused-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-focused-state-background-color-material: rgba(158, 168, 168, 1);
    --jqx-active-state-text-color-material: rgba(158, 168, 168, 1);
    --jqx-active-state-background-color-material: rgba(158, 168, 168, 0.2);
    --jqx-accordion-item-title-text-color-material: rgba(0, 0, 0, 0.87);
    --jqx-accordion-item-title-background-color-material: rgba(255, 255, 255, 1);
    --jqx-accordion-item-title-border-color-material: rgba(227, 227, 227, 1);
    --jqx-accordion-item-title-hovered-text-color-material: rgba(255, 255, 255, 1);
    --jqx-accordion-item-title-hovered-background-color-material: rgba(158, 168, 168, 1);
    --jqx-accordion-item-title-hovered-border-color-material: rgba(224, 224, 224, 1);
    --jqx-accordion-item-title-selected-text-color-material: rgba(255, 255, 255, 1);
    --jqx-accordion-item-title-selected-background-color-material: rgba(158, 168, 168, 1);
    --jqx-accordion-item-title-selected-border-color-material: rgba(224, 224, 224, 1);
    --jqx-accordion-item-title-focused-border-color-material: rgba(158, 168, 168, 1);
    --jqx-accordion-item-text-color-material: rgba(85, 85, 85, 1);
    --jqx-accordion-item-background-color-material: rgba(255, 255, 255, 1);
    --jqx-accordion-item-focused-text-color-material: rgba(85, 85, 85, 1);
    --jqx-accordion-item-focused-background-color-material: rgba(255, 255, 255, 1);
    --jqx-accordion-item-focused-border-color-material: rgba(158, 168, 168, 1);
    --jqx-button-default-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-default-background-color-material: rgba(158, 168, 168, 1);
    --jqx-button-default-hovered-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-default-hovered-state-background-color-material: rgba(26, 43, 106, 1);
    --jqx-button-default-focused-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-default-focused-state-background-color-material: rgba(158, 168, 168, 1);
    --jqx-button-default-active-state-text-color-material: rgba(158, 168, 168, 1);
    --jqx-button-default-active-state-background-color-material: rgba(158, 168, 168, 0.2);
    --jqx-button-primary-text-color-material: rgba(28, 168, 221, 1);
    --jqx-button-primary-background-color-material: rgba(255, 255, 255, 1);
    --jqx-button-primary-hovered-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-primary-hovered-state-background-color-material: rgba(28, 168, 221, 1);
    --jqx-button-primary-focused-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-primary-focused-state-background-color-material: rgba(28, 168, 221, 1);
    --jqx-button-primary-active-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-primary-active-state-background-color-material: rgba(28, 168, 221, 1);
    --jqx-button-success-text-color-material: rgba(92, 184, 92, 1);
    --jqx-button-success-background-color-material: rgba(255, 255, 255, 1);
    --jqx-button-success-hovered-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-success-hovered-state-background-color-material: rgba(92, 184, 92, 1);
    --jqx-button-success-focused-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-success-focused-state-background-color-material: rgba(92, 184, 92, 1);
    --jqx-button-success-active-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-success-active-state-background-color-material: rgba(92, 184, 92, 1);
    --jqx-button-warning-text-color-material: rgba(240, 173, 78, 1);
    --jqx-button-warning-background-color-material: rgba(255, 255, 255, 1);
    --jqx-button-warning-hovered-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-warning-hovered-state-background-color-material: rgba(240, 173, 78, 1);
    --jqx-button-warning-focused-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-warning-focused-state-background-color-material: rgba(240, 173, 78, 1);
    --jqx-button-warning-active-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-warning-active-state-background-color-material: rgba(240, 173, 78, 1);
    --jqx-button-danger-text-color-material: rgba(217, 83, 79, 1);
    --jqx-button-danger-background-color-material: rgba(255, 255, 255, 1);
    --jqx-button-danger-hovered-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-danger-hovered-state-background-color-material: rgba(217, 83, 79, 1);
    --jqx-button-danger-focused-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-danger-focused-state-background-color-material: rgba(217, 83, 79, 1);
    --jqx-button-danger-active-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-danger-active-state-background-color-material: rgba(217, 83, 79, 1);
    --jqx-button-info-text-color-material: rgba(91, 192, 222, 1);
    --jqx-button-info-background-color-material: rgba(255, 255, 255, 1);
    --jqx-button-info-hovered-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-info-hovered-state-background-color-material: rgba(91, 192, 222, 1);
    --jqx-button-info-focused-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-info-focused-state-background-color-material: rgba(91, 192, 222, 1);
    --jqx-button-info-active-state-text-color-material: rgba(255, 255, 255, 1);
    --jqx-button-info-active-state-background-color-material: rgba(91, 192, 222, 1);
    --jqx-editors-text-editors-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-editors-text-editors-background-color-material: rgba(255, 255, 255, 1);
    --jqx-editors-text-editors-border-color-material: rgba(227, 227, 227, 1);
    --jqx-editors-text-editors-border-radius-material: 2px;
    --jqx-editors-text-editors-focused-border-color-material: rgba(158, 168, 168, 1);
    --jqx-editors-combobox-multi-item-text-color-material: rgba(255, 255, 255, 1);
    --jqx-editors-combobox-multi-item-background-color-material: rgba(158, 168, 168, 1);
    --jqx-editors-calendar-header-text-color-material: rgba(0, 0, 0, 0.87);
    --jqx-editors-calendar-header-background-color-material: rgba(255, 255, 255, 1);
    --jqx-editors-calendar-background-color-material: rgba(255, 255, 255, 1);
    --jqx-editors-calendar-today-cell-text-color-material: rgba(85, 85, 85, 1);
    --jqx-editors-calendar-today-cell-background-color-material: rgba(255, 255, 219, 1);
    --jqx-editors-calendar-today-cell-border-color-material: rgba(26, 43, 106, 1);
    --jqx-editors-calendar-current-month-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-editors-calendar-other-month-text-color-material: rgba(137, 137, 137, 1);
    --jqx-editors-calendar-hovered-cell-text-color-material: rgba(51, 51, 51, 1);
    --jqx-editors-calendar-hovered-cell-background-color-material: rgba(245, 245, 245, 1);
    --jqx-editors-calendar-selected-cell-text-color-material: rgba(158, 168, 168, 1);
    --jqx-editors-calendar-selected-cell-background-color-material: rgba(158, 168, 168, 0.2);
    --jqx-editors-switch-thumb-color-material: rgba(158, 168, 168, 1);
    --jqx-editors-switch-on-label-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-editors-switch-on-label-background-color-material: rgba(255, 255, 255, 1);
    --jqx-editors-switch-off-label-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-editors-switch-off-label-background-color-material: rgba(255, 255, 255, 1);
    --jqx-editors-file-uploader-background-color-material: rgba(255, 255, 255, 1);
    --jqx-editors-file-uploader-filename-text-color-material: rgba(85, 85, 85, 1);
    --jqx-grid-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-grid-background-color-material: rgba(255, 255, 255, 1);
    --jqx-grid-header-text-color-material: rgba(0, 0, 0, 0.87);
    --jqx-grid-header-text-weight-material: normal;
    --jqx-grid-header-background-color-material: rgba(255, 255, 255, 1);
    --jqx-grid-row-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-grid-row-background-color-material: rgba(255, 255, 255, 1);
    --jqx-grid-row-alternation-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-grid-row-alternation-background-color-material: rgba(249, 249, 249, 1);
    --jqx-grid-hovered-row-text-color-material: rgba(51, 51, 51, 1);
    --jqx-grid-hovered-row-background-color-material: rgba(245, 245, 245, 1);
    --jqx-grid-selected-row-text-color-material: rgba(158, 168, 168, 1);
    --jqx-grid-selected-row-background-color-material: rgba(158, 168, 168, 0.2);
    --jqx-grid-selected-row-border-color-material: rgba(158, 168, 168, 0.2);
    --jqx-grid-group-row-text-color-material: rgba(85, 85, 85, 1);
    --jqx-grid-group-row-background-color-material: rgba(255, 255, 255, 1);
    --jqx-grid-editor-text-color-material: rgba(85, 85, 85, 1);
    --jqx-grid-editor-background-color-material: rgba(255, 255, 255, 1);
    --jqx-grid-pinned-cells-background-color-material: rgba(229, 229, 229, 1);
    --jqx-list-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-list-background-color-material: rgba(255, 255, 255, 1);
    --jqx-list-header-text-color-material: rgba(0, 0, 0, 0.87);
    --jqx-list-header-background-color-material: rgba(255, 255, 255, 1);
    --jqx-list-hovered-item-state-text-color-material: rgba(51, 51, 51, 1);
    --jqx-list-hovered-item-state-background-color-material: rgba(245, 245, 245, 1);
    --jqx-list-selected-item-state-text-color-material: rgba(158, 168, 168, 1);
    --jqx-list-selected-item-state-background-color-material: rgba(158, 168, 168, 0.2);
    --jqx-menu-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-menu-background-color-material: rgba(255, 255, 255, 1);
    --jqx-menu-dropdown-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-menu-dropdown-background-color-material: rgba(255, 255, 255, 1);
    --jqx-menu-hovered-item-color-material: rgba(51, 51, 51, 1);
    --jqx-menu-hovered-item-background-color-material: rgba(245, 245, 245, 1);
    --jqx-menu-selected-item-color-material: rgba(158, 168, 168, 1);
    --jqx-menu-selected-item-background-color-material: rgba(158, 168, 168, 0.2);
    --jqx-menu-selected-item-border-color-material: rgba(158, 168, 168, 0.2);
    --jqx-navbar-item-text-color-material: rgba(255, 255, 255, 1);
    --jqx-navbar-item-background-color-material: rgba(158, 168, 168, 1);
    --jqx-navbar-hovered-item-text-color-material: rgba(255, 255, 255, 1);
    --jqx-navbar-hovered-item-background-color-material: rgba(158, 168, 168, 0.9);
    --jqx-navbar-selected-item-text-color-material: rgba(255, 255, 255, 1);
    --jqx-navbar-selected-item-background-color-material: rgba(158, 168, 168, 1);
    --jqx-overlays-content-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-overlays-content-background-color-material: rgba(255, 255, 255, 1);
    --jqx-overlays-shader-background-color-material: rgba(0, 0, 0, 0.6);
    --jqx-overlays-popup-header-text-color-material: rgba(0, 0, 0, 0.87);
    --jqx-overlays-popup-header-background-color-material: rgba(255, 255, 255, 1);
    --jqx-overlays-tooltip-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-overlays-tooltip-background-color-material: rgba(255, 255, 255, 1);
    --jqx-overlays-toast-text-color-material: rgba(255, 255, 255, 1);
    --jqx-overlays-toast-info-background-color-material: rgba(91, 192, 222, 1);
    --jqx-overlays-toast-warning-background-color-material: rgba(240, 173, 78, 1);
    --jqx-overlays-toast-error-background-color-material: rgba(217, 83, 79, 1);
    --jqx-overlays-toast-success-background-color-material: rgba(92, 184, 92, 1);
    --jqx-pivotgrid-header-text-color-material: rgba(0, 0, 0, 0.87);
    --jqx-pivotgrid-header-background-color-material: rgba(255, 255, 255, 1);
    --jqx-pivotgrid-cell-text-color-material: rgba(0, 0, 0, 0.6);
    --jqx-pivotgrid-cell-background-color-material: rgba(255, 255, 255, 1);
    --jqx-pivotgrid-selected-cell-text-color-material: rgba(158, 168, 168, 1);
    --jqx-pivotgrid-selected-cell-background-color-material: rgba(158, 168, 168, 0.2);
    --jqx-progressbar-bar-background-color-material: rgba(255, 255, 255, 1);
    --jqx-progressbar-selected-range-background-color-material: rgba(158, 168, 168, 1);
    --jqx-progressbar-label-color-material: rgba(0, 0, 0, 0.6);
    --jqx-scheduler-header-text-color-material: rgba(0, 0, 0, 0.87);
    --jqx-scheduler-header-background-color-material: rgba(255, 255, 255, 1);
    --jqx-scheduler-header-border-color-material: rgba(227, 227, 227, 1);
    --jqx-scheduler-header-buttons-text-color-material: rgba(255, 255, 255, 1);
    --jqx-scheduler-header-buttons-background-color-material: rgba(172, 180, 179, 1);
    --jqx-scheduler-header-buttons-hovered-text-color-material: rgba(255, 255, 255, 1);
    --jqx-scheduler-header-buttons-hovered-background-color-material: rgba(158, 168, 168, 1);
    --jqx-scheduler-header-buttons-selected-text-color-material: rgba(255, 255, 255, 1);
    --jqx-scheduler-header-buttons-selected-background-color-material: rgba(158, 168, 168, 1);
    --jqx-scheduler-border-color-material: rgba(227, 227, 227, 1);
    --jqx-scheduler-not-work-time-cells-color-material: rgba(238, 238, 238, 1);
    --jqx-scheduler-hovered-cell-background-color-material: rgba(245, 245, 245, 1);
    --jqx-scheduler-hovered-cell-border-color-material: rgba(245, 245, 245, 1);
    --jqx-scheduler-selected-cell-background-color-material: rgba(158, 168, 168, 0.2);
    --jqx-scheduler-selected-cell-border-color-material: rgba(158, 168, 168, 0.2);
    --jqx-scrollbar-scrollbar-background-color-material: rgba(245, 245, 245, 1);
    --jqx-scrollbar-scrollbar-thumb-background-color-material: rgba(245, 245, 245, 1);
    --jqx-scrollbar-scrollbar-thumb-hovered-background-color-material: rgba(230, 230, 230, 1);
    --jqx-scrollbar-scrollbar-thumb-active-background-color-material: rgba(217, 217, 217, 1);
    --jqx-scrollbar-scrollbar-arrow-background-color-material: rgba(245, 245, 245, 1);
    --jqx-scrollbar-scrollbar-arrow-hovered-background-color-material: rgba(232, 232, 232, 1);
    --jqx-scrollbar-scrollbar-arrow-active-background-color-material: rgba(217, 217, 217, 1);
    --jqx-scrollview-indicator-background-color-material: rgba(250, 250, 250, 1);
    --jqx-scrollview-selected-indicator-background-color-material: rgba(250, 250, 250, 1);
    --jqx-slider-slider-bar-background-color-material: rgba(240, 240, 240, 1);
    --jqx-slider-selected-slider-background-color-material: rgba(158, 168, 168, 0.2);
    --jqx-slider-slider-thumb-color-material: rgba(158, 168, 168, 1);
    --jqx-slider-focused-border-color-material: rgba(158, 168, 168, 1);
    --jqx-slider-tooltip-text-color-material: rgba(255, 255, 255, 1);
    --jqx-slider-tooltip-background-color-material: rgba(158, 168, 168, 1);
    --jqx-slider-tooltip-border-color-material: rgba(227, 227, 227, 1);
    --jqx-slider-arrows-background-color-material: rgba(255, 255, 255, 0);
    --jqx-slider-arrows-hovered-background-color-material: rgba(158, 168, 168, 1);
    --jqx-slider-arrows-selected-background-color-material: rgba(158, 168, 168, 0.2);
    --jqx-tabs-header-background-color-material: rgba(255, 255, 255, 1);
    --jqx-tabs-tab-text-color-material: rgba(0, 0, 0, 0.87);
    --jqx-tabs-hovered-tab-text-color-material: rgba(255, 255, 255, 1);
    --jqx-tabs-hovered-tab-background-color-material: rgba(158, 168, 168, 1);
    --jqx-tabs-selected-tab-text-color-material: rgba(158, 168, 168, 1);
    --jqx-tabs-selected-tab-background-color-material: rgba(255, 255, 255, 1);
    --jqx-tabs-selected-tab-bottom-border-color-material: rgba(158, 168, 168, 1);
    --jqx-tabs-border-color-material: rgba(224, 224, 224, 1);
    --jqx-tabs-content-text-color-material: rgba(85, 85, 85, 1);
    --jqx-tabs-content-background-color-material: rgba(255, 255, 255, 1);
    --jqx-toolbar-background-color-material: rgba(255, 255, 255, 1);
    --jqx-treeview-hovered-tree-item-color-material: rgba(51, 51, 51, 1);
    --jqx-treeview-hovered-tree-item-background-color-material: rgba(245, 245, 245, 1);
    --jqx-treeview-selected-tree-item-color-material: rgba(158, 168, 168, 1);
    --jqx-treeview-selected-tree-item-background-color-material: rgba(158, 168, 168, 0.2);
 }
 
 /*Rounded Corners*/
 /*top-left rounded Corners*/
 .jqx-rc-tl-material {
     border-top-left-radius: 500em;
     border-top-left-radius: var(--jqx-border-radius-material);
 }
 /*top-right rounded Corners*/
 .jqx-rc-tr-material {
     border-top-right-radius: 500em;
     border-top-right-radius: var(--jqx-border-radius-material);
 }
 /*bottom-left rounded Corners*/
 .jqx-rc-bl-material {
     border-bottom-left-radius: 500em;
     border-bottom-left-radius: var(--jqx-border-radius-material);
 }
 /*bottom-right rounded Corners*/
 .jqx-rc-br-material {
     border-bottom-right-radius: 500em;
     border-bottom-right-radius: var(--jqx-border-radius-material);
 }
 /*top rounded Corners*/
 .jqx-rc-t-material {
     border-top-left-radius: 500em;
     border-top-left-radius: var(--jqx-border-radius-material);
     border-top-right-radius: 500em;
     border-top-right-radius: var(--jqx-border-radius-material);
 }
 /*bottom rounded Corners*/
 .jqx-rc-b-material {
     border-bottom-left-radius: 500em;
     border-bottom-left-radius: var(--jqx-border-radius-material);
     border-bottom-right-radius: 500em;
     border-bottom-right-radius: var(--jqx-border-radius-material);
 }
 /*right rounded Corners*/
 .jqx-rc-r-material {
     border-top-right-radius: 500em;
     border-top-right-radius: var(--jqx-border-radius-material);
     border-bottom-right-radius: 500em;
     border-bottom-right-radius: var(--jqx-border-radius-material);
 }
 /*left rounded Corners*/
 .jqx-rc-l-material {
     border-top-left-radius: 500em;
     border-top-left-radius: var(--jqx-border-radius-material);
     border-bottom-left-radius: 500em;
     border-bottom-left-radius: var(--jqx-border-radius-material);
 }
 /*all rounded Corners*/
 .jqx-rc-all-material {
     border-radius: 500em;
     border-radius: var(--jqx-border-radius-material);
 }

 .jqx-icon-arrow-down{
    width: 100%;
    height: 100%;
    border-color: transparent !important;
}
 
 .jqx-widget-material, .jqx-widget-header-material, .jqx-fill-state-normal-material,
 .jqx-widget-content-material, .jqx-fill-state-hover-material, .jqx-fill-state-pressed-material {
     font-family: ;
     font-family: var(--jqx-font-family-material);
     font-size: 14px;
     font-size: var(--jqx-font-size-material);
 }
 
 .jqx-widget-material {
     font-family: ;
     font-family: var(--jqx-font-family-material);
     font-size: 14px;
     font-size: var(--jqx-font-size-material);
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-text-color-material);
 }
 
 .jqx-widget-content-material {
     font-family: ;
     font-family: var(--jqx-font-family-material);
     font-size: 14px;
     font-size: var(--jqx-font-size-material);
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-text-color-material);
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-background-color-material);
     border-color: rgba(227, 227, 227, 1);
     border-color: var(--jqx-border-color-material);
 }
 
 .jqx-widget-header-material {
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-header-background-color-material);
     border-color: rgba(227, 227, 227, 1);
     border-color: var(--jqx-border-color-material);
     color: rgba(0, 0, 0, 0.87);
     color: var(--jqx-header-text-color-material);
     font-weight: 500;
     *zoom: 1;
     font-family: ;
     font-family: var(--jqx-font-family-material);
     background: rgba(255, 255, 255, 1);
     background: var(--jqx-header-background-color-material);;
     color: rgba(0, 0, 0, 0.87);;
     color: var(--jqx-header-text-color-material);
     font-size: 14px;
     font-size: var(--jqx-font-size-material);
 }
 
 
 .jqx-fill-state-normal-material {
     border-color: rgba(158, 168, 168, 1);
     border-color: var(--jqx-accent-color-material);
     *zoom: 1;
     background: rgba(255, 255, 255, 1);
     background: var(--jqx-background-color-material);
 }
 .jqx-widget-material input::selection, input.jqx-input-widget-material::selection, .jqx-widget-content-material input::selection {
     background: rgba(158, 168, 168, 1);
     background: var(--jqx-accent-color-material);
     color: rgba(255, 255, 255, 1);
     color: var(--jqx-background-color-material);
 }
 .jqx-toolbar-material{
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-toolbar-background-color-material);
      border-color: rgba(227, 227, 227, 1);
      border-color: var(--jqx-border-color-material);
 }
 
 .jqx-button-material, jqx-button-material.jqx-fill-state-normal-material {
     color: rgba(255, 255, 255, 1);
     color: var(--jqx-button-default-text-color-material);
     background: rgba(158, 168, 168, 1);
     background: var(--jqx-button-default-background-color-material);
     border-color: rgba(158, 168, 168, 1);
     border-color: var(--jqx-button-default-background-color-material);
     *zoom: 1;
     outline: none;
     transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
     border-radius: 500em;
     border-radius: var(--jqx-border-radius-material);
 }
 .jqx-button-material button, jqx-button-material input {
     background: transparent;
     color: inherit;
     border:none;
     outline: none;
 }
 
 
 .jqx-slider-button-material {
     padding:3px;
     background: transparent;
     border:transparent;
 }
     .jqx-button-material.float {
         border-radius: 100%;
         min-height: 48px;
         min-width: 48px;
         width: 48px;
         height: 48px;
         max-height: 48px;
         max-width:48px;
     }
 
     .jqx-button-material.outlined {
         background: transparent;
         color: rgba(158, 168, 168, 1);
         color: var(--jqx-accent-color-material);
         border-width: 2px;
     }
 
     .jqx-button-material.flat {
         background: transparent;
         color: rgba(158, 168, 168, 1);
         color: var(--jqx-accent-color-material);
         border: none;
     }
 
 .jqx-fill-state-hover-material {
 }
 
 .jqx-fill-state-pressed-material {
 }
 
 .jqx-fill-state-hover-material, .jqx-fill-state-focus-material {
     text-decoration: none;
 }
 
 .jqx-expander-header.jqx-fill-state-hover-material,
 .jqx-expander-header.jqx-fill-state-normal-material
 {
      background-color: rgba(255, 255, 255, 1) !important;
      background-color: var(--jqx-accordion-item-title-background-color-material) !important;
      border-color: rgba(227, 227, 227, 1);
      border-color: var(--jqx-accordion-item-title-border-color-material);
      color: rgba(0, 0, 0, 0.87) !important;
      color: var(--jqx-accordion-item-title-text-color-material) !important;
 }
 
 .jqx-expander-header.jqx-fill-state-pressed-material {
    background-color: rgba(158, 168, 168, 1) !important;
    background-color: var(--jqx-accordion-item-title-selected-background-color-material) !important;
    border-color: rgba(224, 224, 224, 1);
    border-color: var(--jqx-accordion-item-title-selected-border-color-material);
    color: rgba(255, 255, 255, 1) !important;
    color: var(--jqx-accordion-item-title-selected-text-color-material) !important;
 }
 
 .jqx-expander-header.jqx-fill-state-hover-material {
    background-color: rgba(158, 168, 168, 1) !important;
    background-color: var(--jqx-accordion-item-title-hovered-background-color-material) !important;
    color: rgba(255, 255, 255, 1) !important;
    color: var(--jqx-accordion-item-title-hovered-text-color-material) !important;
    border-color: rgba(224, 224, 224, 1) !important;
    border-color: var(--jqx-accordion-item-title-hovered-border-color-material) !important;
 }
 .jqx-expander-header.jqx-fill-state-focus-material {
    border-color: rgba(158, 168, 168, 1) !important;
    border-color: var(--jqx-accordion-item-title-focused-border-color-material) !important;
 }
 
 .jqx-expander-content-material {
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-accordion-item-background-color-material);
     color: rgba(85, 85, 85, 1);
     color: var(--jqx-accordion-item-text-color-material);
 }
 
 .jqx-expander-content.jqx-fill-state-focus-material {
    border-color: rgba(158, 168, 168, 1) !important;
    border-color: var(--jqx-accordion-item-focused-border-color-material) !important;
    background-color: rgba(255, 255, 255, 1);
    background-color: var(--jqx-accordion-item-focused-background-color-material);
    color: rgba(85, 85, 85, 1);
    color: var(--jqx-accordion-item-focused-text-color-material);
 }
 
 .jqx-expander-header-material {
     padding:10px;
 }
 .jqx-button-material.jqx-fill-state-hover {
     opacity: 0.9;
     cursor: pointer;
     box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
     *zoom: 1;
     color: rgba(255, 255, 255, 1);
     color: var(--jqx-button-default-hovered-state-text-color-material);
 }
 
     .jqx-button-material.jqx-fill-state-hover.outlined,
     .jqx-button-material.jqx-fill-state-hover.flat {
         color: rgba(158, 168, 168, 1);
         color: var(--jqx-accent-color-material);
         box-shadow: none;
     }
 
 .jqx-button-material.jqx-fill-state-pressed {
     cursor: pointer;
     background: rgba(158, 168, 168, 1);
     background: var(--jqx-accent-color-material);
     box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
 }
 
     .jqx-button-material.jqx-fill-state-pressed.float {
         box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
     }
 
     .jqx-slider-button-material.jqx-fill-state-pressed-material,
     .jqx-button-material.jqx-fill-state-pressed.outlined,
     .jqx-button-material.jqx-fill-state-pressed.flat {
         background: rgba(179,229,252,0.15);
         box-shadow: none;
         color: rgba(158, 168, 168, 1);
         color: var(--jqx-accent-color-material); 
     }
 
 .jqx-button-material.jqx-fill-state-focus {
     background: rgba(158, 168, 168, 1);
     background: var(--jqx-button-default-focused-state-background-color-material);
     box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
 }
   .jqx-slider-button-material.jqx-fill-state-focus-material {
       background: transparent;
       border-color: transparent;
       box-shadow:none;
   }
   
     .jqx-button-material.jqx-fill-state-focus.outlined,
     .jqx-button-material.jqx-fill-state-focus.flat {
         box-shadow: none;
         background: rgba(99,0,238,0.15);
         color: #0069A8;
     }
 
 .jqx-dropdownlist-state-normal-material, .jqx-dropdownlist-state-hover-material, .jqx-dropdownlist-state-selected-material,
 .jqx-scrollbar-button-state-hover-material, .jqx-scrollbar-button-state-normal-material, .jqx-scrollbar-button-state-pressed-material,
 .jqx-scrollbar-thumb-state-normal-horizontal-material, .jqx-scrollbar-thumb-state-hover-horizontal-material, .jqx-scrollbar-thumb-state-pressed-horizontal-material,
 .jqx-scrollbar-thumb-state-normal-material, .jqx-scrollbar-thumb-state-pressed-material, .jqx-tree-item-hover-material, .jqx-tree-item-selected-material,
 .jqx-tree-item-material, .jqx-menu-item-material, .jqx-menu-item-hover-material, .jqx-menu-item-selected-material, .jqx-menu-item-top-material, .jqx-menu-item-top-hover-material,
 .jqx-menu-item-top-selected-material, .jqx-slider-button-material, .jqx-slider-slider-material {
     transition: background-color 100ms linear;
 }
 
 
 .jqx-primary-material.jqx-input-label-material {
    color: rgba(28, 168, 221, 1) !important;
    color: var(--jqx-button-primary-text-color-material) !important; 
 }
 .jqx-primary-material.jqx-input-bar-material:before {
    background: rgba(28, 168, 221, 1) !important;
    background: var(--jqx-button-primary-text-color-material) !important;
 }
 .jqx-success-material.jqx-input-label-material {
    color: rgba(92, 184, 92, 1) !important;
    color: var(--jqx-button-success-text-color-material) !important;
 }
 .jqx-success-material.jqx-input-bar-material:before {
    background: rgba(92, 184, 92, 1) !important;
    background: var(--jqx-button-success-text-color-material) !important;
 }
 .jqx-inverse-material.jqx-input-label-material {
    color: #666 !important;
 }
 .jqx-inverse-material.jqx-input-bar-material:before {
    background: #666 !important;
 }
 .jqx-danger-material.jqx-input-label-material {
    color: rgba(217, 83, 79, 1) !important;
    color: var(--jqx-button-danger-text-color-material) !important;
 }
 .jqx-danger-material.jqx-input-bar-material:before {
    background: rgba(217, 83, 79, 1) !important;
    background: var(--jqx-button-danger-text-color-material) !important;
 }
 .jqx-warning-material.jqx-input-label-material {
    color: rgba(240, 173, 78, 1) !important;
    color: var(--jqx-button-warning-text-color-material) !important;
 }
 .jqx-warning-material.jqx-input-bar-material:before {
    background: rgba(240, 173, 78, 1) !important;
    background: var(--jqx-button-warning-text-color-material) !important;
 }
 .jqx-info-material.jqx-input-label-material {
    color: rgba(91, 192, 222, 1) !important;
    color: var(--jqx-button-info-text-color-material) !important;
 }
 .jqx-info-material.jqx-input-bar-material:before {
    background: rgba(91, 192, 222, 1) !important;
    background: var(--jqx-button-info-text-color-material) !important;
 }
 
 .jqx-slider-tooltip-material.jqx-primary-slider, .jqx-slider-tooltip-material.jqx-primary-slider .jqx-fill-state-normal-material {
     border-color: rgba(28, 168, 221, 1);
     border-color: var(--jqx-button-primary-text-color-material);
     background: rgba(28, 168, 221, 1);
     background: var(--jqx-button-primary-text-color-material);
 }
 .jqx-slider-tooltip-material.jqx-success-slider, .jqx-slider-tooltip-material.jqx-success-slider .jqx-fill-state-normal-material {
     border-color: rgba(92, 184, 92, 1);
     border-color: var(--jqx-button-success-text-color-material);
     background: rgba(92, 184, 92, 1);
     background: var(--jqx-button-success-text-color-material);
 }
 .jqx-slider-tooltip-material.jqx-inverse-slider, .jqx-slider-tooltip-material.jqx-inverse-slider .jqx-fill-state-normal-material {
     border-color: #666;
     background: #666;
 }
 .jqx-slider-tooltip-material.jqx-danger-slider, .jqx-slider-tooltip-material.jqx-danger-slider .jqx-fill-state-normal-material {
     border-color: rgba(217, 83, 79, 1);
     border-color: var(--jqx-button-danger-text-color-material);
     background: rgba(217, 83, 79, 1);
     background: var(--jqx-button-danger-text-color-material);
 }
 .jqx-slider-tooltip-material.jqx-warning-slider, .jqx-slider-tooltip-material.jqx-warning-slider .jqx-fill-state-normal-material {
     border-color: rgba(240, 173, 78, 1);
     border-color: var(--jqx-button-warning-text-color-material);
     background: rgba(240, 173, 78, 1);
     background: var(--jqx-button-warning-text-color-material);
 }
 .jqx-slider-tooltip-material.jqx-info-slider, .jqx-slider-tooltip-material.jqx-info-slider .jqx-fill-state-normal-material {
     border-color: rgba(91, 192, 222, 1);
     border-color: var(--jqx-button-info-text-color-material);;
     background: rgba(91, 192, 222, 1);;
     background: var(--jqx-button-info-text-color-material);;
 }
 
 .jqx-primary-material {
     color: rgba(28, 168, 221, 1) !important;
     color: var(--jqx-button-primary-text-color-material) !important;
     background: rgba(255, 255, 255, 1) !important;
     background: var(--jqx-button-primary-background-color-material) !important;
     border-color: rgba(28, 168, 221, 1) !important;
     border-color: var(--jqx-button-primary-text-color-material) !important;
     text-shadow: none !important;
 }
 
     .jqx-primary-material.jqx-dropdownlist-state-normal-material,
     .jqx-primary-material.jqx-slider-button-material,
     .jqx-primary-material.jqx-slider-slider-material,
     .jqx-primary-material.jqx-combobox-arrow-normal-material,
     .jqx-primary-material.jqx-combobox-arrow-hover-material,
     .jqx-primary-material.jqx-action-button-material,
     .jqx-primary-material:hover,    
     .jqx-primary-material:active,
     .jqx-primary-material.active,
     .jqx-primary-material.disabled,
     .jqx-primary-material[disabled] {
         color: rgba(255, 255, 255, 1) !important;
         color: var(--jqx-button-primary-hovered-state-text-color-material) !important;
         background: rgba(28, 168, 221, 1) !important;
         background: var(--jqx-button-primary-hovered-state-background-color-material) !important;
         border-color: rgba(28, 168, 221, 1) !important;
         border-color: var(--jqx-button-primary-hovered-state-background-color-material) !important;
         text-shadow: none !important;
     }
 
     .jqx-primary-material:focus {
         color: rgba(255, 255, 255, 1) !important;
         color: var(--jqx-button-primary-focused-state-text-color-material) !important;
         background: rgba(28, 168, 221, 1) !important;
         background: var(--jqx-button-primary-focused-state-background-color-material) !important;
         border-color: rgba(28, 168, 221, 1) !important;
         border-color: var(--jqx-button-primary-focused-state-background-color-material) !important;
     }
 
     .jqx-fill-state-pressed-material.jqx-primary-material,
     .jqx-primary-material:active,
     .jqx-primary-material.active {
         color:rgba(255, 255, 255, 1) !important;
         color:var(--jqx-button-primary-active-state-text-color-material) !important;
         background-color: rgba(28, 168, 221, 1) !important;
         background-color: var(--jqx-button-primary-active-state-background-color-material) !important;
         border-color: rgba(28, 168, 221, 1) !important;
         border-color: var(--jqx-button-primary-active-state-background-color-material) !important;
         text-shadow: none !important;
     }
 
 .jqx-success-material {
     color: rgba(92, 184, 92, 1) !important;
     color: var(--jqx-button-success-text-color-material) !important;
     background: rgba(255, 255, 255, 1) !important;
     background: var(--jqx-button-success-background-color-material) !important;
     border-color: rgba(92, 184, 92, 1) !important;
     border-color: var(--jqx-button-success-text-color-material) !important;
     text-shadow: none !important;
 }
 
     .jqx-success-material.jqx-dropdownlist-state-normal-material,
     .jqx-success-material.jqx-slider-button-material,
     .jqx-success-material.jqx-slider-slider-material,
     .jqx-success-material.jqx-combobox-arrow-normal-material,
     .jqx-success-material.jqx-combobox-arrow-hover-material,
     .jqx-success-material.jqx-action-button-material,
     .jqx-success-material:hover
     .jqx-success-material:active,
     .jqx-success-material.active,
     .jqx-success-material.disabled,
     .jqx-success-material[disabled] {
         color: rgba(255, 255, 255, 1) !important;
         color: var(--jqx-button-success-hovered-state-text-color-material) !important;
         background: rgba(92, 184, 92, 1) !important;
         background: var(--jqx-button-success-hovered-state-background-color-material) !important;
         border-color: rgba(92, 184, 92, 1) !important;
         border-color: var(--jqx-button-success-hovered-state-background-color-material) !important;
         text-shadow: none !important;
     }
 
     .jqx-success-material:focus {
         color: rgba(255, 255, 255, 1) !important;
         color: var(--jqx-button-success-focused-state-text-color-material) !important;
         background: rgba(92, 184, 92, 1) !important;
         background: var(--jqx-button-success-focused-state-background-color-material) !important;
         border-color: rgba(92, 184, 92, 1) !important;
         border-color: var(--jqx-button-success-focused-state-background-color-material) !important;
     }
 
     .jqx-fill-state-pressed-material.jqx-success-material,
     .jqx-success-material:active,
     .jqx-success-material.active {
         color:rgba(255, 255, 255, 1) !important;
         color:var(--jqx-button-success-active-state-text-color-material) !important;
         background-color: rgba(92, 184, 92, 1) !important;
         background-color: var(--jqx-button-success-active-state-background-color-material) !important;
         border-color: rgba(92, 184, 92, 1) !important;
         border-color: var(--jqx-button-success-active-state-background-color-material) !important;
         text-shadow: none !important;
     }
 
 .jqx-inverse-material {
     text-shadow: none !important;
     color: #666 !important;
     background: #fff !important;
     border-color: #cccccc !important;
 }
 
     .jqx-inverse-material.jqx-dropdownlist-state-normal-material,
     .jqx-inverse-material.jqx-slider-button-material,
     .jqx-inverse-material.jqx-slider-slider-material,
     .jqx-inverse-material.jqx-combobox-arrow-hover-material,
     .jqx-inverse-material.jqx-combobox-arrow-normal-material,
     .jqx-inverse-material.jqx-action-button-material,
     .jqx-inverse-material:hover,
     .jqx-inverse-material:focus,
     .jqx-inverse-material:active,
     .jqx-inverse-material.active,
     .jqx-inverse-material.disabled,
     .jqx-inverse-material[disabled] {
         text-shadow: none !important;
         color: #666 !important;
         background: #cccccc !important;
         border-color: #cccccc !important;
     }
 
     .jqx-fill-state-pressed-material.jqx-inverse-material,
     .jqx-inverse-material:active,
     .jqx-inverse-material.active {
         text-shadow: none !important;
         color: #666 !important;
         background: #cccccc !important;
         border-color: #cccccc !important;
     }
 
 
 .jqx-danger-material {
     text-shadow: none !important;
     color: rgba(217, 83, 79, 1) !important;
     color: var(--jqx-button-danger-text-color-material) !important;
     background: rgba(255, 255, 255, 1) !important;
     background: var(--jqx-button-danger-background-color-material) !important;
     border-color: rgba(217, 83, 79, 1) !important;
     border-color: var(--jqx-button-danger-text-color-material) !important;
 }
 
     .jqx-danger-material.jqx-dropdownlist-state-normal-material,
     .jqx-danger-material.jqx-slider-button-material,
     .jqx-danger-material.jqx-slider-slider-material,
     .jqx-danger-material.jqx-combobox-arrow-hover-material,
     .jqx-danger-material.jqx-combobox-arrow-normal-material,
     .jqx-danger-material.jqx-action-button-material,
     .jqx-danger-material:hover,
     .jqx-danger-material:active,
     .jqx-danger-material.active,
     .jqx-danger-material.disabled,
     .jqx-danger-material[disabled] {
         text-shadow: none !important;
         color: rgba(255, 255, 255, 1) !important;
         color: var(--jqx-button-danger-hovered-state-text-color-material) !important;
         background: rgba(217, 83, 79, 1) !important;
         background: var(--jqx-button-danger-hovered-state-background-color-material) !important;
         border-color: rgba(217, 83, 79, 1) !important;
         border-color: var(--jqx-button-danger-hovered-state-background-color-material) !important;
     }
 
     .jqx-danger-material:focus {
         color: rgba(255, 255, 255, 1) !important;
         color: var(--jqx-button-danger-focused-state-text-color-material) !important;
         background: rgba(217, 83, 79, 1) !important;
         background: var(--jqx-button-danger-focused-state-background-color-material) !important;
         border-color: rgba(217, 83, 79, 1) !important;
         border-color: var(--jqx-button-danger-focused-state-background-color-material) !important;
     }
 
     .jqx-fill-state-pressed-material.jqx-danger-material,
     .jqx-danger-material:active,
     .jqx-danger-material.active {
         text-shadow: none !important;
         color:rgba(255, 255, 255, 1) !important;
         color:var(--jqx-button-danger-active-state-text-color-material) !important;
         background-color: rgba(217, 83, 79, 1) !important;
         background-color: var(--jqx-button-danger-active-state-background-color-material) !important;
         border-color: rgba(217, 83, 79, 1) !important;
         border-color: var(--jqx-button-danger-active-state-background-color-material) !important;
     }
 
 .jqx-validator-error-label-material {
     color: #d9534f !important;
 }
 
 .jqx-warning-material {
     text-shadow: none !important;
     color: rgba(240, 173, 78, 1) !important;
     color: var(--jqx-button-warning-text-color-material) !important;
     background: rgba(255, 255, 255, 1) !important;
     background: var(--jqx-button-warning-background-color-material) !important;
     border-color: rgba(240, 173, 78, 1) !important;
     border-color: var(--jqx-button-warning-text-color-material) !important;
 }
 
     .jqx-warning-material.jqx-dropdownlist-state-normal-material,
     .jqx-warning-material.jqx-slider-button-material,
     .jqx-warning-material.jqx-slider-slider-material,
     .jqx-warning-material.jqx-combobox-arrow-hover-material,
     .jqx-warning-material.jqx-combobox-arrow-normal-material,
     .jqx-warning-material.jqx-action-button-material,
     .jqx-warning-material:hover
     .jqx-warning-material:active,
     .jqx-warning-material.active,
     .jqx-warning-material.disabled,
     .jqx-warning-material[disabled] {
         text-shadow: none !important;
         color: rgba(255, 255, 255, 1) !important;
         color: var(--jqx-button-warning-hovered-state-text-color-material) !important;
         background: rgba(240, 173, 78, 1) !important;
         background: var(--jqx-button-warning-hovered-state-background-color-material) !important;
         border-color: rgba(240, 173, 78, 1) !important;
         border-color: var(--jqx-button-warning-hovered-state-background-color-material) !important;
     }
 
     .jqx-warning-material:focus {
         color: rgba(255, 255, 255, 1) !important;
         color: var(--jqx-button-warning-focused-state-text-color-material) !important;
         background: rgba(240, 173, 78, 1) !important;
         background: var(--jqx-button-warning-focused-state-background-color-material) !important;
         border-color: rgba(240, 173, 78, 1) !important;
         border-color: var(--jqx-button-warning-focused-state-background-color-material) !important;
     }
 
     .jqx-fill-state-pressed-material.jqx-warning-material,
     .jqx-warning-material:active,
     .jqx-warning-material.active {
         text-shadow: none !important;
         color:rgba(255, 255, 255, 1) !important;
         color:var(--jqx-button-warning-active-state-text-color-material) !important;
         background-color: rgba(240, 173, 78, 1) !important;
         background-color: var(--jqx-button-warning-active-state-background-color-material) !important;
         border-color: rgba(240, 173, 78, 1) !important;
         border-color: var(--jqx-button-warning-active-state-background-color-material) !important;
     }
 
 
 .jqx-info-material {
     text-shadow: none !important;
     color: rgba(91, 192, 222, 1) !important;
     color: var(--jqx-button-info-text-color-material) !important;
     background: rgba(255, 255, 255, 1) !important;
     background: var(--jqx-button-info-background-color-material) !important;
     border-color: rgba(91, 192, 222, 1) !important;
     border-color: var(--jqx-button-info-text-color-material) !important;
 }
 
     .jqx-info-material.jqx-dropdownlist-state-normal-material,
     .jqx-info-material.jqx-slider-button-material,
     .jqx-info-material.jqx-slider-slider-material,
     .jqx-info-material.jqx-combobox-arrow-hover-material,
     .jqx-info-material.jqx-combobox-arrow-normal-material,
     .jqx-info-material.jqx-action-button-material,
     .jqx-info-material:hover,
     .jqx-info-material:active,
     .jqx-info-material.active,
     .jqx-info-material.disabled,
     .jqx-info-material[disabled] {
         color: rgba(255, 255, 255, 1) !important;
         color: var(--jqx-button-info-hovered-state-text-color-material) !important;
         background: rgba(91, 192, 222, 1) !important;
         background: var(--jqx-button-info-hovered-state-background-color-material) !important;
         border-color: rgba(91, 192, 222, 1) !important;
         border-color: var(--jqx-button-info-hovered-state-background-color-material) !important;
         text-shadow: none !important;
     }
 
     .jqx-info-material:focus {
         color: rgba(255, 255, 255, 1) !important;
         color: var(--jqx-button-info-focused-state-text-color-material) !important;
         background: rgba(91, 192, 222, 1) !important;
         background: var(--jqx-button-info-focused-state-background-color-material) !important;
         border-color: rgba(91, 192, 222, 1) !important;
         border-color: var(--jqx-button-info-focused-state-background-color-material) !important;
     }
 
     .jqx-fill-state-pressed-material.jqx-info-material,
     .jqx-info-material:active,
     .jqx-info-material.active {
         text-shadow: none !important;
         color:rgba(255, 255, 255, 1) !important;
         color:var(--jqx-button-info-active-state-text-color-material) !important;
         background-color: rgba(91, 192, 222, 1) !important;
         background-color: var(--jqx-button-info-active-state-background-color-material) !important;
         border-color: rgba(91, 192, 222, 1) !important;
         border-color: var(--jqx-button-info-active-state-background-color-material) !important;
     }
 
     .jqx-loader-material, .jqx-popover-content-material {
         color: rgba(0, 0, 0, 0.6);
         color: var(--jqx-overlays-content-text-color-material);
         background-color: rgba(255, 255, 255, 1);
         background-color: var(--jqx-overlays-content-background-color-material);
     }
     
     .jqx-popover-modal-background-material {
         background-color: rgba(0, 0, 0, 0.6);
         background-color: var(--jqx-overlays-shader-background-color-material);
     }
     
     .jqx-popover-title-material {
         color: rgba(0, 0, 0, 0.87) !important;
         color: var(--jqx-overlays-popup-header-text-color-material) !important;
         background-color: rgba(255, 255, 255, 1) !important;
         background-color: var(--jqx-overlays-popup-header-background-color-material) !important;
     }
     
     .jqx-loader-text-material {
         color: rgba(0, 0, 0, 0.6);
         color: var(--jqx-overlays-content-text-color-material);
     }
     
     .jqx-notification-material {
         color: rgba(255, 255, 255, 1) !important;
         color: var(--jqx-overlays-toast-text-color-material) !important;
     }
     
     .jqx-notification-info.jqx-notification-material {
         background-color: rgba(91, 192, 222, 1) !important;
         background-color: var(--jqx-overlays-toast-info-background-color-material) !important;
         border-color: rgba(91, 192, 222, 1) !important;
         border-color: var(--jqx-overlays-toast-info-background-color-material) !important;
     }  
     
     .jqx-notification-warning.jqx-notification-material {
         background-color: rgba(240, 173, 78, 1) !important;
         background-color: var(--jqx-overlays-toast-warning-background-color-material) !important;
         border-color: rgba(240, 173, 78, 1) !important;
         border-color: var(--jqx-overlays-toast-warning-background-color-material) !important;
     }  
     
     .jqx-notification-error.jqx-notification-material {
         background-color: rgba(217, 83, 79, 1) !important;
         background-color: var(--jqx-overlays-toast-error-background-color-material) !important;
         border-color: rgba(217, 83, 79, 1) !important;
         border-color: var(--jqx-overlays-toast-error-background-color-material) !important;
     }  
     
     .jqx-notification-success.jqx-notification-material {
         background-color: rgba(92, 184, 92, 1) !important;
         background-color: var(--jqx-overlays-toast-success-background-color-material) !important;
         border-color: rgba(92, 184, 92, 1) !important;
         border-color: var(--jqx-overlays-toast-success-background-color-material) !important;
     } 
 
 .jqx-fill-state-pressed-material {
     background-image: none;
     outline: 0;
 }
 
 .jqx-grid-group-column-material {
     border-color: transparent;
 }
 .jqx-grid-column-menubutton-material {
     border-width: 0px;
 }
 .jqx-grid-groups-row-material > span {
     padding-left: 4px;
 }
 
 .jqx-grid-cell-material, .jqx-grid-group-cell-material {
     box-shadow: none;
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-grid-row-background-color-material);
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-grid-row-text-color-material);
 }
 
 .jqx-grid-cell-alt-material {
     background-color: rgba(249, 249, 249, 1) !important;
     background-color: var(--jqx-grid-row-alternation-background-color-material) !important;
     color: rgba(0, 0, 0, 0.6) !important;
     color: var(--jqx-grid-row-alternation-text-color-material) !important;
 }
 
 .jqx-grid-pager-top-material .jqx-button-material,
 .jqx-grid-pager-material .jqx-button-material {
     color: rgba(0,0,0,.54) !important;
     background-color: transparent;
     border-color: transparent;
     position: relative;
     height:30px !important;
     width:30px !important;
     border-radius:50%;
     top: -4px;
 }
 
 .jqx-grid-pager-input-material  {
     padding:0px !important;
 }
 
 .jqx-grid-pager-top-material .jqx-button-material > div,
 .jqx-grid-pager-material .jqx-button-material > div {
     top: 3px;
     position: relative;
     left: 2px;
 }
 
 .jqx-grid-pager-top-material .jqx-button-material.jqx-fill-state-hover,
 .jqx-grid-pager-top-material .jqx-button-material.jqx-fill-state-pressed,
 .jqx-grid-pager-material .jqx-button-material.jqx-fill-state-hover,
 .jqx-grid-pager-material .jqx-button-material.jqx-fill-state-pressed
 {
     color: rgba(0,0,0,.54) !important;
     background-color: transparent;
     border-color: transparent;
     box-shadow: none;    
 }
 
 .jqx-grid-pager-top-material .jqx-grid-pager-number-material,
 .jqx-grid-pager-material .jqx-grid-pager-number-material {
 
     background-color: transparent;
     border-color: transparent;
     color: rgba(0,0,0,.54) !important;
     font-size:12px;
 }
 
 .jqx-grid-pager-top-material .jqx-grid-pager-number-material:hover,
 .jqx-grid-pager-material .jqx-grid-pager-number-material:hover {
     font-size:12px;
 }
 
 .jqx-grid-pager-top-material .jqx-grid-pager-number-material.jqx-fill-state-pressed-material ,
 .jqx-grid-pager-material .jqx-grid-pager-number-material.jqx-fill-state-pressed-material {
     color: rgba(158, 168, 168, 1) !important;
     color: var(--jqx-accent-color-material) !important;
     font-weight: bold !important;
 }
 
 .jqx-grid-column-menubutton-material {
     background-color: transparent;
     border-color: #E0E0E0 !important;
 }
 
 .jqx-grid-column-header-material, .jqx-grid-groups-header-material, .jqx-grid-pager-material {
     background-color: rgba(255, 255, 255, 1) !important;
     background-color: var(--jqx-grid-header-background-color-material) !important;
     color: rgba(0, 0, 0, 0.87) !important;
     color: var(--jqx-grid-header-text-color-material) !important;
     font-weight: normal;
     font-weight: var(--jqx-grid-header-text-weight-material);
 }
 
 .jqx-grid-group-expand-material + div, .jqx-grid-group-collapse-material + div {
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-grid-group-row-background-color-material);
     color: rgba(85, 85, 85, 1);
     color: var(--jqx-grid-group-row-text-color-material);
 }
 
 .jqx-grid-cell-pinned-material {
     background-color: rgba(229, 229, 229, 1) !important;
     background-color: var(--jqx-grid-pinned-cells-background-color-material) !important;
 }
 
 #groupsheadergrid .jqx-fill-state-normal-material {
     background-color: rgba(255, 255, 255, 1) !important;
     background-color: var(--jqx-grid-background-color-material) !important;
 }
 
 .jqx-cell-material {
     font-size: 13px;
 }
 
 .jqx-calendar-material > div {
     padding: 10px;
     box-sizing: border-box;
 }
 
 .jqx-calendar-row-header-material, .jqx-calendar-top-left-header-material {
     background-color: #f0f0f0;
     border: 0px solid #f2f2f2;
 }
 
 .jqx-calendar-title-header-material {
     color: rgba(0, 0, 0, 0.87);
     color: var(--jqx-editors-calendar-header-text-color-material);
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-editors-calendar-header-background-color-material);
 }
 .jqx-calendar-column-header-material {
     color: rgba(0, 0, 0, 0.87);
     color: var(--jqx-editors-calendar-header-text-color-material);
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-editors-calendar-header-background-color-material);
     border-top: 1px solid rgba(255, 255, 255, 1);
     border-top: 1px solid var(--jqx-editors-calendar-header-background-color-material);
     border-bottom: 1px solid rgba(255, 255, 255, 1);
     border-bottom: 1px solid var(--jqx-editors-calendar-header-background-color-material);
 }
 
 .jqx-calendar-month-container-material {
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-editors-calendar-background-color-material);
 }
 
 .jqx-calendar-cell-month-material {
     color: rgba(0, 0, 0, 0.6) !important;
     color: var(--jqx-editors-calendar-current-month-text-color-material) !important;
 }
 
 .jqx-calendar-cell-othermonth-material {
     color: rgba(137, 137, 137, 1) !important;
     color: var(--jqx-editors-calendar-other-month-text-color-material) !important;
 }
 
 .jqx-calendar-cell-today-material {
     color: rgba(85, 85, 85, 1) !important;
     color: var(--jqx-editors-calendar-today-cell-text-color-material) !important;
     background-color: rgba(255, 255, 219, 1);
     background-color: var(--jqx-editors-calendar-today-cell-background-color-material);
     border-color: rgba(26, 43, 106, 1);
     border-color: var(--jqx-editors-calendar-today-cell-border-color-material);
 }
 
 .jqx-calendar-cell-hover-material {
     color: rgba(51, 51, 51, 1) !important;
     color: var(--jqx-editors-calendar-hovered-cell-text-color-material) !important;
     background-color: rgba(245, 245, 245, 1);
     background-color: var(--jqx-editors-calendar-hovered-cell-background-color-material);
     border-color: rgba(245, 245, 245, 1);
     border-color: var(--jqx-editors-calendar-hovered-cell-background-color-material); 
 }
 .jqx-calendar-cell-selected-material {
     color: rgba(158, 168, 168, 1) !important;
     color: var(--jqx-editors-calendar-selected-cell-text-color-material) !important;
     background-color: rgba(158, 168, 168, 0.2);
     background-color: var(--jqx-editors-calendar-selected-cell-background-color-material);
     border-color: rgba(158, 168, 168, 0.2);
     border-color: var(--jqx-editors-calendar-selected-cell-background-color-material);
 }
 
 .jqx-expander-header-material {
     padding-top: 10px;
     padding-bottom: 10px;
 }
 
 .jqx-ribbon-header-vertical-material, .jqx-widget-header-vertical-material {
     background: #fff;
 }
 
 .jqx-scrollbar-state-normal-material {
     background-color: rgba(245, 245, 245, 1);
     background-color: var(--jqx-scrollbar-scrollbar-background-color-material);
     border: 1px solid #f5f5f5;
     border-left-color: #ddd;
 }
 
 .jqx-scrollbar-button-state-normal-material {
     background-color: rgba(245, 245, 245, 1);
     background-color: var(--jqx-scrollbar-scrollbar-arrow-background-color-material);
 }
 
 .jqx-scrollbar-button-state-hover-material {
     background: rgba(232, 232, 232, 1);
     background: var(--jqx-scrollbar-scrollbar-arrow-hovered-background-color-material);
 }
 
 .jqx-scrollbar-button-state-pressed-material {
     background: rgba(217, 217, 217, 1) !important;
     background: var(--jqx-scrollbar-scrollbar-arrow-active-background-color-material) !important;
     /* border-color: var(--jqx-scrollbar-scrollbar-arrow-active-background-color-material); */
 }
 
 .jqx-scrollbar-thumb-state-normal-material, .jqx-scrollbar-thumb-state-normal-horizontal-material {
     background: rgba(245, 245, 245, 1);
     background: var(--jqx-scrollbar-scrollbar-thumb-background-color-material);
     border-color: #b3b3b3;
 }
 
 .jqx-scrollbar-thumb-state-hover-material, .jqx-scrollbar-thumb-state-hover-horizontal-material {
     background: rgba(230, 230, 230, 1);
     background: var(--jqx-scrollbar-scrollbar-thumb-hovered-background-color-material);
     border-color: #b3b3b3;
     box-shadow: none;
     -webkit-box-shadow: none;
     -moz-box-shadow: none;
 }
 
  .jqx-scrollview-button-material {
     background-color: rgba(250, 250, 250, 1);
     background-color: var(--jqx-scrollview-indicator-background-color-material);
 }
 
 .jqx-scrollview-button-selected-material {
     background-color: rgba(250, 250, 250, 1);
     background-color: var(--jqx-scrollview-selected-indicator-background-color-material);
 }
 
 .jqx-progressbar-material {
     background: rgba(255, 255, 255, 1) !important;
     background: var(--jqx-progressbar-bar-background-color-material) !important;
     box-shadow: none;
 }
 
 .jqx-progressbar-value-material, .jqx-progressbar-value-vertical-material {
     background: rgba(158, 168, 168, 1);
     background: var(--jqx-progressbar-selected-range-background-color-material);
 }
 
 .jqx-progressbar-text-material {
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-progressbar-label-color-material);
 }
 
 .jqx-splitter-collapse-button-vertical-material, .jqx-splitter-collapse-button-horizontal-material {
     background: rgba(158, 168, 168, 1);
     background: var(--jqx-accent-color-material);
 }
 
 
 .jqx-scrollbar-thumb-state-pressed-material, .jqx-splitter-splitbar-vertical-material, .jqx-splitter-splitbar-horizontal-material, .jqx-scrollbar-thumb-state-pressed-horizontal-material,
 .jqx-scrollbar-button-state-pressed-material {
     background: rgba(217, 217, 217, 1);
     background: var(--jqx-scrollbar-scrollbar-thumb-active-background-color-material);
     border-color: #b3b3b3;
     box-shadow: none;
     -webkit-box-shadow: none;
     -moz-box-shadow: none;
 }
 
 .jqx-grid-column-sortdescbutton-material, jqx-grid-column-filterbutton-material, .jqx-grid-column-sortascbutton-material {
     background-color: transparent;
     border-style: solid;
     border-width: 0px 0px 0px 0px;
     border-color: rgba(227, 227, 227, 1);
     border-color: var(--jqx-border-color-material);
 }
 
 .jqx-menu-vertical-material, .jqx-menu-horizontal-material {
     background: rgba(255, 255, 255, 1);
     background: var(--jqx-menu-background-color-material);
     -webkit-filter: none;
             filter: none;
 }
 
 .jqx-menu-dropdown-material {
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-menu-dropdown-background-color-material);
 }
 
 .jqx-grid-bottomright-material, .jqx-panel-bottomright-material, .jqx-listbox-bottomright-material {
     background-color: #fafafa;
 }
 
 .jqx-window-material, .jqx-tooltip-material {
     box-shadow: 0 4px 23px 5px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0,0,0,0.15);
 }
 .jqx-tooltip-material, .jqx-tooltip-material.jqx-popup-material, .jqx-tooltip-material .jqx-fill-state-normal-material {
     background: rgba(255, 255, 255, 1);
     background: var(--jqx-overlays-tooltip-background-color-material);
     border-color: rgba(255, 255, 255, 1);
     border-color: var(--jqx-overlays-tooltip-background-color-material);
     box-shadow: none;
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-overlays-tooltip-text-color-material);
 }
 .jqx-docking-material .jqx-window-material {
     box-shadow: none;
 }
 
 .jqx-docking-panel-material .jqx-window-material {
     box-shadow: none;
 }
 
 .jqx-checkbox-material {
     line-height:20px;
     overflow: visible;
 }
 .jqx-radiobutton-material {
     overflow: visible;
     box-shadow: none;
     -webkit-box-shadow: none;
     -moz-box-shadow: none;
     background-repeat: no-repeat;
     background: none;
     line-height:20px;
 }
 
 .jqx-radiobutton-material-material, .jqx-radiobutton-hover-material {
     border-radius: 100%;
     background-repeat: no-repeat;
     transition: background-color ease-in .3s;
 }
 
 .jqx-radiobutton-check-checked-material {
     -webkit-filter: none;
             filter: none;
     background: rgba(255, 255, 255, 1);
     background: var(--jqx-background-color-material);
     background-repeat: no-repeat;
     border-radius: 100%;
 }
 
 .jqx-radiobutton-check-indeterminate-material {
     -webkit-filter: none;
             filter: none;
     background: #999;
     border-radius: 100%;
 }
 
 .jqx-radiobutton-check-indeterminate-disabled-material {
     -webkit-filter: none;
             filter: none;
     background: #999;
     border-radius: 100%;
 }
 
 .jqx-checkbox-default-material,
 .jqx-radiobutton-default-material
  {
     border-width: 1px;
     border-color: rgba(227, 227, 227, 1);
     border-color: var(--jqx-border-color-material);
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-background-color-material);
     overflow: visible;
 }
 
 .jqx-tree-material .jqx-checkbox-material .jqx-checkbox-default-material,
 .jqx-checkbox-material[checked] .jqx-checkbox-default-material,
 .jqx-tree-grid-checkbox[checked].jqx-checkbox-default-material,
 .jqx-radiobutton-material[checked] .jqx-radiobutton-default-material
  {
     background-color: rgba(158, 168, 168, 1);
     background-color: var(--jqx-accent-color-material);
     border-color: rgba(158, 168, 168, 1);
     border-color: var(--jqx-accent-color-material);
 }
 
 .jqx-checkbox-check-indeterminate-material {
     width:14px !important;
     height:14px !important;
     position:relative;
     top: 1px;
     left: 1px;
     background: white;
 }
 .jqx-tree-material .jqx-checkbox-check-indeterminate-material {
     width:12px !important;
     height:12px !important;
     top: 2px;
     left:2px;
 }
 
 .jqx-checkbox-hover-material,
 .jqx-radiobutton-hover-material {
     background-color: rgba(158, 168, 168, 1);
     background-color: var(--jqx-accent-color-material);
     border-color: rgba(158, 168, 168, 1);
     border-color: var(--jqx-accent-color-material);
 }
 
 
 .jqx-slider-slider-material {
     transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s ease;
 }
 
 .jqx-slider-slider-material:active {
     transform: scale(1.2);
     box-shadow: rgba(0,0,0,0.3) 0 0 10px;
 }
 .jqx-slider-material[discrete] .jqx-slider-slider-material:active
  {
     transform: scaleX(0);
     
 }
 .jqx-slider-slider-horizontal-material, .jqx-slider-slider-vertical-material {
     background: rgba(158, 168, 168, 1);
     background: var(--jqx-slider-slider-thumb-color-material);
     border-color: rgba(158, 168, 168, 1);
     border-color: var(--jqx-slider-slider-thumb-color-material);  
 }
 
 .jqx-slider-has-value-material.jqx-fill-state-focus-material,
 .jqx-slider-button-material.jqx-fill-state-focus-material {
     border-color: rgba(158, 168, 168, 1) !important;
     border-color: var(--jqx-slider-focused-border-color-material) !important;
 }
 
 .jqx-slider-tooltip-material {
     width: 25px;
     height: 25px;
     transform-origin: 50% 100%;
     border-radius: 50%;
     transform: scale(0) rotate(45deg);
     padding:0px;
     background: transparent !important;
 }
 .jqx-slider-tooltip-material.init {
      transform: scale(1) rotate(45deg);
 }
 .jqx-slider-tooltip-material.hide {
      transition: transform 0.2s ease;
      transform-origin:50% 100%;
      transform: scale(0) rotate(45deg); 
 }
 .jqx-slider-tooltip-material.show {
      transition: transform 0.2s ease;
      transform: scale(1) rotate(45deg); 
 }
 
 
 .jqx-slider-tooltip-material .jqx-tooltip-arrow-t-b,
 .jqx-slider-tooltip-material .jqx-tooltip-arrow-l-r {
     display:none;
     visibility:hidden;
 }
 
 .jqx-slider-tooltip-material, .jqx-slider-tooltip-material .jqx-fill-state-normal-material {
     border-radius: 15px 15px 0px;
     display: flex;
     align-items: center;
     justify-content: center;
     background: rgba(158, 168, 168, 1);
     background: var(--jqx-slider-tooltip-background-color-material); 
     color: rgba(255, 255, 255, 1); 
     color: var(--jqx-slider-tooltip-text-color-material);
     border-color: rgba(227, 227, 227, 1);
     border-color: var(--jqx-slider-tooltip-border-color-material);
     font-size:11px;
 }
 .jqx-slider-tooltip-material.far, .jqx-slider-tooltip-material.far .jqx-fill-state-normal-material {
    border-radius: 0px 15px 15px 15px; 
 }
 .jqx-slider-tooltip-material.vertical, .jqx-slider-tooltip-material.vertical .jqx-fill-state-normal-material {
    border-radius: 15px 0px 15px 15px; 
 }
 .jqx-slider-tooltip-material.vertical.far, .jqx-slider-tooltip-material.vertical.far .jqx-fill-state-normal-material {
    border-radius: 15px 15px 15px 0px; 
 }
 .jqx-slider-tooltip-material {
     background:transparent;
     border:none !important;
     box-shadow:none;
 }
 .jqx-slider-tooltip-material .jqx-tooltip-main-material {
     top: -7px;
     right: 11px;
 }
 .jqx-slider-tooltip-material.far .jqx-tooltip-main-material {
     top: 3px;
     right: 4px;
 }
 .jqx-slider-tooltip-material.vertical .jqx-tooltip-main-material {
     top: -3px;
     right: 3px;
 }
 .jqx-slider-tooltip-material .jqx-tooltip-text {
     background: transparent;
     border:none;
     padding: 0px;
     overflow:visible;
 }
 .jqx-slider-tooltip-material .jqx-tooltip-text>span {
      transform: rotate(-45deg);
 }
 .jqx-slider-tooltip-material.range {
     width: 35px;
     height:35px;
 }
 
 .jqx-slider-rangebar-material {
     border-color: rgba(158, 168, 168, 0.2);
     border-color: var(--jqx-slider-selected-slider-background-color-material);
     background: rgba(158, 168, 168, 0.2);
     background: var(--jqx-slider-selected-slider-background-color-material);
 }
 
 .jqx-slider-track-horizontal-material, .jqx-slider-track-vertical-material {
     border-color: rgba(227, 227, 227, 1);
     border-color: var(--jqx-border-color-material);
     background: rgba(240, 240, 240, 1);
     background: var(--jqx-slider-slider-bar-background-color-material);
 }
 
 .jqx-slider-button-material {
     border-radius: 100%;
 }
 .jqx-slider-button-material.jqx-fill-state-normal-material {
     background: rgba(255, 255, 255, 0) !important;
     background: var(--jqx-slider-arrows-background-color-material) !important;
 }
 
 .jqx-slider-button-material.jqx-fill-state-hover-material {
     background: rgba(158, 168, 168, 1) !important;
     background: var(--jqx-slider-arrows-hovered-background-color-material) !important;
 }
 
 .jqx-slider-button-material.jqx-fill-state-pressed-material {
     background: rgba(158, 168, 168, 0.2) !important;
     background: var(--jqx-slider-arrows-selected-background-color-material) !important;
 }
 
 .jqx-listitem-state-hover-material,
 .jqx-listitem-state-selected-material,
 .jqx-listitem-state-normal-material {
     padding: 5px;
     margin:0px;
 }
 
 .jqx-listitem-state-normal-material, .jqx-listmenu-item-material.jqx-fill-state-normal-material {
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-list-text-color-material);
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-list-background-color-material);
 }
 
 .jqx-listitem-state-group-material, .jqx-listmenu-header-material {
     color: rgba(0, 0, 0, 0.87);
     color: var(--jqx-list-header-text-color-material);
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-list-header-background-color-material);
 }
 
 .jqx-menu-item-top-material {
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-menu-text-color-material);
 }
 .jqx-menu-item-material {
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-menu-dropdown-text-color-material);
 }
 
 .jqx-scheduler-edit-dialog-label-material {
   line-height: 35px;
   padding-top: 6px;
   padding-bottom: 6px;
 
 }
 .jqx-scheduler-edit-dialog-field-material {
   line-height: 35px;
   padding-top: 6px;
   padding-bottom: 6px;
 }
 .jqx-scheduler-edit-dialog-label-rtl-material {
   line-height: 35px;
   padding-top: 6px;
   padding-bottom: 6px;
 }
 .jqx-scheduler-edit-dialog-field-rtl-material {
   line-height: 35px;
   padding-top: 6px;
   padding-bottom: 6px;
 }
 
 /*applied to a list item when the item is selected.*/ 
 .jqx-input-popup-material .jqx-fill-state-hover-material,
 .jqx-input-popup-material .jqx-fill-state-pressed-material {
     color: rgba(51, 51, 51, 1) !important;
     color: var(--jqx-hovered-state-content-text-color-material) !important;
     border-color: rgba(245, 245, 245, 1);
     border-color: var(--jqx-hovered-state-content-background-color-material);
     text-decoration: none;
     background-color: rgba(245, 245, 245, 1);
     background-color: var(--jqx-hovered-state-content-background-color-material);
     background-repeat: repeat-x;
     outline: 0;
     background: rgba(245, 245, 245, 1);
     background: var(--jqx-hovered-state-content-background-color-material); /* Old browsers */
     box-shadow: none;
     background-position: 0 0;
 }
 
 .jqx-tree-item-hover-material {
     color: rgba(51, 51, 51, 1) !important;
     color: var(--jqx-treeview-hovered-tree-item-color-material) !important;
     border-color: rgba(245, 245, 245, 1);
     border-color: var(--jqx-treeview-hovered-tree-item-background-color-material);
     background-color: rgba(245, 245, 245, 1);
     background-color: var(--jqx-treeview-hovered-tree-item-background-color-material);
     background: rgba(245, 245, 245, 1);
     background: var(--jqx-treeview-hovered-tree-item-background-color-material); /* Old browsers */
 }
 
 .jqx-menu-item-hover-material, .jqx-menu-vertical-material .jqx-menu-item-top-hover-material,
 .jqx-menu-item-top-material.jqx-fill-state-hover-material {
     color: rgba(51, 51, 51, 1);
     color: var(--jqx-menu-hovered-item-color-material);
     background-color: rgba(245, 245, 245, 1);
     background-color: var(--jqx-menu-hovered-item-background-color-material);
     border-color: rgba(245, 245, 245, 1);
     border-color: var(--jqx-menu-hovered-item-background-color-material);
 }
 
 .jqx-listitem-state-hover-material {
     color: rgba(51, 51, 51, 1) !important;
     color: var(--jqx-list-hovered-item-state-text-color-material) !important;
     border-color: rgba(245, 245, 245, 1);
     border-color: var(--jqx-list-hovered-item-state-background-color-material);
     background-color: rgba(245, 245, 245, 1);
     background-color: var(--jqx-list-hovered-item-state-background-color-material);
 }
 
 .jqx-grid-cell-hover-material {
     color: rgba(51, 51, 51, 1) !important;
     color: var(--jqx-grid-hovered-row-text-color-material) !important;
     background-color: rgba(245, 245, 245, 1) !important;
     background-color: var(--jqx-grid-hovered-row-background-color-material) !important;
 }
 
 .jqx-scheduler-material {
     border-color: rgba(227, 227, 227, 1);
     border-color: var(--jqx-scheduler-header-border-color-material);
 }
 
 .jqx-scheduler-material .jqx-scrollbar-state-normal-material {
     border-left-color: rgba(227, 227, 227, 1);
     border-left-color: var(--jqx-scheduler-header-border-color-material);
 }
 
 .jqx-scheduler-material .jqx-widget-header-material{
     color: rgba(0, 0, 0, 0.87) !important;
     color: var(--jqx-scheduler-header-text-color-material) !important;
     background-color: rgba(255, 255, 255, 1) !important;
     background-color: var(--jqx-scheduler-header-background-color-material) !important;
     border-color: rgba(227, 227, 227, 1) !important;
     border-color: var(--jqx-scheduler-header-border-color-material) !important;
 }
 
 .jqx-scheduler-all-day-cell-material {
     border-color: rgba(227, 227, 227, 1) !important;
     border-color: var(--jqx-scheduler-header-border-color-material) !important;
 }
 
 .jqx-scheduler-toolbar-material .jqx-datetimeinput-material {
     border-color: rgba(227, 227, 227, 1) !important;
     border-color: var(--jqx-scheduler-header-border-color-material) !important;
 }
 
 .jqx-scheduler-toolbar-material .jqx-group-button-normal-material {
     color: rgba(255, 255, 255, 1);
     color: var(--jqx-scheduler-header-buttons-text-color-material);
     border-color: rgba(172, 180, 179, 1);
     border-color: var(--jqx-scheduler-header-buttons-background-color-material);
     background: rgba(172, 180, 179, 1);
     background: var(--jqx-scheduler-header-buttons-background-color-material);
 }
 .jqx-scheduler-toolbar-material .jqx-group-button-normal-material.jqx-fill-state-hover-material {
     color: rgba(255, 255, 255, 1);
     color: var(--jqx-scheduler-header-buttons-hovered-text-color-material);
     background: rgba(158, 168, 168, 1);
     background: var(--jqx-scheduler-header-buttons-hovered-background-color-material);
 }
 .jqx-scheduler-toolbar-material .jqx-group-button-normal-material.jqx-fill-state-pressed-material {
     color: rgba(255, 255, 255, 1) !important;
     color: var(--jqx-scheduler-header-buttons-selected-text-color-material) !important;
     border-color: rgba(158, 168, 168, 1) !important;
     border-color: var(--jqx-scheduler-header-buttons-selected-background-color-material) !important;
     background-color: rgba(158, 168, 168, 1) !important;
     background-color: var(--jqx-scheduler-header-buttons-selected-background-color-material) !important;
 }
 
 .jqx-scheduler-work-time-cell-material, .jqx-scheduler-not-work-time-cell-material {
     border-color: rgba(227, 227, 227, 1) !important;
     border-color: var(--jqx-scheduler-border-color-material) !important; 
 }
 .jqx-scheduler-not-work-time-cell-material {
     background-color: rgba(238, 238, 238, 1);
     background-color: var(--jqx-scheduler-not-work-time-cells-color-material);
 }
 .jqx-scheduler-cell-hover-material {
     border-color: rgba(245, 245, 245, 1) !important;
     border-color: var(--jqx-scheduler-hovered-cell-border-color-material) !important;
     background: rgba(245, 245, 245, 1) !important;
     background: var(--jqx-scheduler-hovered-cell-background-color-material) !important;
 }
 
 .jqx-scheduler-toolbar-material .jqx-datetimeinput {
     background-color: inherit !important;
 }
 .jqx-scheduler-toolbar-material .jqx-datetimeinput .jqx-action-button-material{
     background-color: inherit !important;
 }
 
 .jqx-grid-selectionarea-material,
 .jqx-input-button-header-material, .jqx-input-button-innerHeader-material {
     color: rgba(158, 168, 168, 1) !important;
     color: var(--jqx-accent-color-material) !important;
     border-color: #E1F5FE !important;
     background: #E1F5FE; /* Old browsers */
     box-shadow: none;
 }
 
 .jqx-tree-item-selected-material {
     color: rgba(158, 168, 168, 1) !important;
     color: var(--jqx-treeview-selected-tree-item-color-material) !important;
     border-color: rgba(158, 168, 168, 0.2) !important;
     border-color: var(--jqx-treeview-selected-tree-item-background-color-material) !important;
     background: rgba(158, 168, 168, 0.2);
     background: var(--jqx-treeview-selected-tree-item-background-color-material); /* Old browsers */
     box-shadow: none;
 }
 
 .jqx-menu-item-selected-material, .jqx-menu-vertical-material .jqx-menu-item-top-selected-material, .jqx-menu-item-top-selected-material {
     color: rgba(158, 168, 168, 1) !important;
     color: var(--jqx-menu-selected-item-color-material) !important;
     border-color: rgba(158, 168, 168, 0.2) !important;
     border-color: var(--jqx-menu-selected-item-border-color-material) !important;
     background-color: rgba(158, 168, 168, 0.2) !important;
     background-color: var(--jqx-menu-selected-item-background-color-material) !important;
     background: rgba(158, 168, 168, 0.2);
     background: var(--jqx-menu-selected-item-background-color-material); /* Old browsers */
 }
 
 .jqx-listitem-state-selected-material, .jqx-listmenu-item-material.jqx-fill-state-pressed-material {
     color: rgba(158, 168, 168, 1) !important;
     color: var(--jqx-list-selected-item-state-text-color-material) !important;
     border-color: rgba(158, 168, 168, 0.2) !important;
     border-color: var(--jqx-list-selected-item-state-background-color-material) !important;
     background: rgba(158, 168, 168, 0.2);
     background: var(--jqx-list-selected-item-state-background-color-material); /* Old browsers */
 }
 
 .jqx-grid-cell-selected-material {
     color: rgba(158, 168, 168, 1) !important;
     color: var(--jqx-grid-selected-row-text-color-material) !important;
     border-color: rgba(158, 168, 168, 0.2) !important;
     border-color: var(--jqx-grid-selected-row-border-color-material) !important;
     background-color: rgba(158, 168, 168, 0.2) !important;
     background-color: var(--jqx-grid-selected-row-background-color-material) !important;
     background: rgba(158, 168, 168, 0.2);
     background: var(--jqx-grid-selected-row-background-color-material); /* Old browsers */
     box-shadow: none;
 }
 
 .jqx-scheduler-cell-selected-material {
     border-color: rgba(158, 168, 168, 0.2) !important;
     border-color: var(--jqx-scheduler-selected-cell-border-color-material) !important;
     background-color: rgba(158, 168, 168, 0.2) !important;
     background-color: var(--jqx-scheduler-selected-cell-background-color-material) !important;
 }
 
 .jqx-grid-column-header-material .sorticon {
     background-color: inherit !important;
 }
 .jqx-grid-column-header-material .jqx-widget-header-material {
     background-color: inherit !important;
 }
 .jqx-widget-header-material.sortasc, .jqx-grid-column-sortascbutton-material,
 .jqx-widget-header-material.sortdesc, .jqx-grid-column-sortdescbutton-material {
     background-color: inherit !important;
 }
 
 .jqx-grid-cell-material .jqx-button-material, .jqx-grid-cell-material .jqx-button-material.jqx-fill-state-hover-material, .jqx-grid-cell-material .jqx-button-material.jqx-fill-state-pressed-material {
     box-shadow: none;
     transition: none;
 }
 
 .jqx-grid-cell-selected.jqx-grid-cell-edit-material input {
     background-color: rgba(255, 255, 255, 1) !important;
     background-color: var(--jqx-grid-editor-background-color-material) !important;
     color: rgba(85, 85, 85, 1) !important;
     color: var(--jqx-grid-editor-text-color-material) !important;
 }
 
 #tabletreegrid .jqx-cell-editor-material {
     background-color: rgba(255, 255, 255, 1) !important;
     background-color: var(--jqx-grid-editor-background-color-material) !important;
     color: rgba(85, 85, 85, 1) !important;
     color: var(--jqx-grid-editor-text-color-material) !important;
 }
 
 .jqx-popup-material {
     border: 1px solid rgba(227, 227, 227, 1);
     border: 1px solid var(--jqx-border-color-material);
     *border-right-width: 2px;
     *border-bottom-width: 2px;
     -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
     -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
 }
 
 
 .jqx-widget-material .jqx-grid-group-expand-material, .jqx-grid-group-expand-material, .jqx-grid-column-menubutton-material, .jqx-grid-column-sortdescbutton-material, .jqx-expander-arrow-top-material, .jqx-window-collapse-button-collapsed-material, .jqx-menu-item-arrow-down-material, .jqx-menu-item-arrow-down-selected-material, .jqx-menu-item-arrow-down-material, .jqx-icon-arrow-down-material {
     background-repeat: no-repeat;
     background-position: center;
 }
 

 .jqx-navbar-material {
     border-color: rgba(158, 168, 168, 1) !important;
     border-color: var(--jqx-navbar-item-background-color-material) !important;
 }
 
 .jqx-navbar-block-material {
     color: rgba(255, 255, 255, 1) !important;
     color: var(--jqx-navbar-item-text-color-material) !important;
     background: rgba(158, 168, 168, 1) !important;
     background: var(--jqx-navbar-item-background-color-material) !important;
     border-color: rgba(158, 168, 168, 1) !important;
     border-color: var(--jqx-navbar-item-background-color-material) !important;
 }
 
 .jqx-navbar-block-material.jqx-fill-state-hover-material {
     color: rgba(255, 255, 255, 1) !important;
     color: var(--jqx-navbar-hovered-item-text-color-material) !important;
     background-color: rgba(158, 168, 168, 0.9) !important;
     background-color: var(--jqx-navbar-hovered-item-background-color-material) !important;
     border-color: rgba(158, 168, 168, 0.9) !important;
     border-color: var(--jqx-navbar-hovered-item-background-color-material) !important;
     opacity: 1
 }
 
 .jqx-navbar-block-material.jqx-fill-state-pressed-material {
     color: rgba(255, 255, 255, 1) !important;
     color: var(--jqx-navbar-selected-item-text-color-material) !important;
     background-color: rgba(158, 168, 168, 1) !important;
     background-color: var(--jqx-navbar-selected-item-background-color-material) !important;
     border-color: rgba(158, 168, 168, 1) !important;
     border-color: var(--jqx-navbar-selected-item-background-color-material) !important;
 }
 
 .jqx-input-button-content-material {
     font-size: 10px;
 }
 
 .jqx-widget .jqx-grid-column-header-cell-material {
     padding-top: 8px;
     padding-bottom: 8px;
     height:30px;
 }
 
 .jqx-widget .jqx-grid-row-cell-material {
     padding-top: 8px;
     padding-bottom: 8px;
     height:30px;
 }
 
 .jqx-widget .jqx-grid-cell-material, .jqx-widget .jqx-grid-column-header-material, .jqx-widget .jqx-grid-group-cell-material {
     border-color: rgba(227, 227, 227, 1);
     border-color: var(--jqx-border-color-material);
 }
 
 .jqx-input-material, .jqx-input-material input, .jqx-maskedinput-material {
     border-radius: 2px !important;
     border-radius: var(--jqx-editors-text-editors-border-radius-material) !important;
 }
 
 .jqx-combobox-material, .jqx-input-material {
     border-color: rgba(227, 227, 227, 1);
     border-color: var(--jqx-editors-text-editors-border-color-material);
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-editors-text-editors-text-color-material);
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-editors-text-editors-background-color-material);
     border-left: none;
     border-right: none;
     border-top: none;
     box-shadow: none;
     -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
 }
 
 .jqx-combobox-content-material,
 .jqx-datetimeinput-content-material
  {
     border-color: transparent;
 }
 .jqx-combobox-arrow-normal-material {
     background: rgba(255, 255, 255, 1);
     background: var(--jqx-background-color-material);
     border-color: transparent;
 }
 
 .jqx-combobox-content-focus-material, 
 .jqx-combobox-state-focus-material,
 .jqx-numberinput-focus-material {
     outline: none;
 }
 
 .jqx-combobox-material, .jqx-input-material {
     box-shadow: none;
 }
 
 .jqx-input-group-material {
     position: relative;
     display: inline-block;
     overflow: visible;
     border: none;
     box-shadow: none;
 }
 
     .jqx-input-group-material input {
         width: 100%;
         height: 100%;
         box-sizing: border-box;
     }
     .jqx-input-group-material textarea {
         width: 100%;
         height: 100%;
         outline: none;
         resize: none;
         border-left: none;
         border-right: none;
         border-top: none;
         border-bottom-color: rgba(227, 227, 227, 1);
         border-bottom-color: var(--jqx-border-color-material);
     }
 .jqx-numberinput-material,
 .jqx-maskedinput-material
  {
     position:relative;
 }
 .jqx-numberinput-material input {
     height:100% !important;
 }
 
 .jqx-input-material.jqx-validator-error-element {
     border-color: transparent !important;
     border-bottom: 1px solid #df2227 !important; 
 }
 .jqx-input-material input,
 .jqx-dropdownlist-state-normal-material,
 .jqx-combobox-state-normal-material,
 .jqx-datetimeinput-material,
 .jqx-numberinput-material,
 .jqx-maskedinput-material
  {
     background: rgba(255, 255, 255, 1);
     background: var(--jqx-editors-text-editors-background-color-material);
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-editors-text-editors-text-color-material);
     border-color: #fff;
     border-radius: 0;
     box-shadow: none;
     border-bottom: 1px solid rgba(227, 227, 227, 1);
     border-bottom: 1px solid var(--jqx-editors-text-editors-border-color-material);
     outline: none;
 }
 .jqx-datetimeinput-material .jqx-action-button-material,
 .jqx-datetimeinput-material .jqx-action-button-rtl-material 
  {
     background-color: transparent;
     border-color: transparent;
 }
     .jqx-datetimeinput-material, .jqx-datetimeinput-material > div,
     .jqx-numberinput-material, .jqx-numberinput-material > div,
     .jqx-maskedinput-material, .jqx-maskedinput-material > div,
     .jqx-dropdownlist-state-normal-material, .jqx-dropdownlist-state-normal-material > div, .jqx-dropdownlist-state-normal-material > div > div,
     .jqx-combobox-state-normal-material, .jqx-combobox-state-normal-material > div, .jqx-combobox-state-normal-material > div > div {
         overflow: visible !important;
     }
 
     .jqx-input-material input:focus {
         border-radius: 0;
         box-shadow: none;
     }
 
 .jqx-input-material input, input[type="text"].jqx-input-material, input[type="password"].jqx-input-material, input[type="text"].jqx-widget-content-material, input[type="textarea"].jqx-widget-content-material, textarea.jqx-input-material {
     font-size: 14px;
     resize: none;
     background: rgba(255, 255, 255, 1);
     background: var(--jqx-editors-text-editors-background-color-material);
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-editors-text-editors-text-color-material);
     border: none;
     border-radius: 0;
     box-sizing:border-box;
     box-shadow: none;
     border-bottom: 1px solid rgba(227, 227, 227, 1);
     border-bottom: 1px solid var(--jqx-editors-text-editors-border-color-material);
 }
 .jqx-input-label-material {
     visibility:inherit;
 }
 .jqx-input-bar-material{
     visibility:inherit;
 }
 input:focus ~ .jqx-input-label-material,
 textarea:focus ~ .jqx-input-label-material,
 .jqx-input-widget-material[hint=true] .jqx-input-label,
 .jqx-text-area-material[hint=true] .jqx-input-label,
 .jqx-dropdownlist-state-selected-material .jqx-input-label,
 .jqx-dropdownlist-state-normal-material[hint=true] .jqx-input-label,
 .jqx-combobox-state-normal-material[hint=true] .jqx-input-label,
 .jqx-combobox-material .jqx-input-label.focused,
 .jqx-dropdownlist-material .jqx-input-label.focused,
 .jqx-datetimeinput-material[hint=true] .jqx-input-label,
 .jqx-maskedinput-material[hint=true] .jqx-input-label,
 .jqx-numberinput-material[hint=true] .jqx-input-label,
 .jqx-formattedinput-material[hint=true] .jqx-input-label
  {
     top: -15px;
     font-size: 12px;
     color: rgba(158, 168, 168, 1);
     color: var(--jqx-editors-text-editors-focused-border-color-material);
 }
 
 .jqx-combobox-material #dropdownlistContentcombobox {
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-editors-text-editors-background-color-material);
 }
 .jqx-dropdownlist-material[default-placeholder="true"] .jqx-input-label {
     visibility: hidden;
 }
 
 
 input:focus ~ .jqx-input-bar:before,
 textarea:focus ~ .jqx-input-bar:before,
 .jqx-dropdownlist-state-selected-material .jqx-input-bar:before,
 .jqx-dropdownlist-material .jqx-input-bar.focused:before,
 .jqx-combobox-material .jqx-input-bar.focused:before,
 .jqx-complex-input-group-material .jqx-input-bar.focused::before,
 .jqx-combobox-state-selected-material .jqx-input-bar:before {
     width: 100%;
 }
 .jqx-complex-input-group-material .jqx-fill-state-normal-material {
     border-color: #fafafa;
 }
 .jqx-input-widget-material input[type="password"]{
     letter-spacing: 0.3em;
 }
 
 .jqx-input-label-material {
     color: rgba(227, 227, 227, 1);
     color: var(--jqx-border-color-material);
     font-size: 14px;
     font-weight: normal;
     position: absolute;
     pointer-events: none;
     left: 2px;
     top:10px;
     top: calc(50% - 7px);
     transition: 300ms ease all;
 }
 .jqx-input-label.initial {
     transition: none;
 }
 .jqx-input-bar-material {
     position: relative;
     display: block;
     z-index:1;
 }
 
     .jqx-input-bar-material:before {
         content: '';
         height: 2px;
         width: 0;
         bottom: 0px;
         position: absolute;
         background: rgba(158, 168, 168, 1);
         background: var(--jqx-editors-text-editors-focused-border-color-material);
         transition: 300ms ease all;
         left: 0%;
     }
 .jqx-formatted-input-spin-button-material, .jqx-input-group-addon-material {
     border-color: #fff;
     background: #fff;
 }
 .jqx-dropdownlist-state-selected-material,
 .jqx-combobox-state-selected-material {
     color: rgba(158, 168, 168, 1);
     color: var(--jqx-accent-color-material);
 }
 
 .jqx-switchbutton-thumb-material {
     border-color: rgba(158, 168, 168, 1);
     border-color: var(--jqx-editors-switch-thumb-color-material);
     background: rgba(255, 255, 255, 1);
     background: var(--jqx-background-color-material);
 }
 
 .jqx-switchbutton-label-on-material {
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-editors-switch-on-label-text-color-material);
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-editors-switch-on-label-background-color-material);
 }
 
 .jqx-switchbutton-label-off-material {
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-editors-switch-off-label-text-color-material);
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-editors-switch-off-label-background-color-material);
 }
 
 .jqx-file-upload-material, .jqx-file-upload-file-row-material {
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-editors-file-uploader-background-color-material);
 }
 .jqx-file-upload-file-name-material {
     color: rgba(85, 85, 85, 1) !important;
     color: var(--jqx-editors-file-uploader-filename-text-color-material) !important;
 }
 
 .jqx-dropdownlist-state-normal-material .jqx-icon-arrow-down-material,
 .jqx-combobox-state-normal-material .jqx-icon-arrow-down-material,
 .sorticon.descending .jqx-grid-column-sorticon-material,
 .jqx-tree-item-arrow-expand-material,
  .jqx-expander-header-material .jqx-icon-arrow-down
  {
     transform: rotate(0deg);
     transition: transform 0.2s ease-out;
 }
 
 .jqx-dropdownlist-state-hover-material {
     background-color: rgba(158, 168, 168, 1);
     background-color: var(--jqx-hovered-state-background-color-material);
     color: rgba(255, 255, 255, 1);
     color: var(--jqx-hovered-state-text-color-material);
 }
 
 .jqx-dropdownlist-state-selected-material {
     background-color: rgba(158, 168, 168, 1);
     background-color: var(--jqx-accent-color-material);
     color: rgba(255, 255, 255, 1);
     color: var(--jqx-background-color-material);
 }
 
 .jqx-dropdownlist-state-selected-material .jqx-icon-arrow-down-material,
 .jqx-combobox-state-selected-material .jqx-icon-arrow-down-material,
 .sorticon.ascending .jqx-grid-column-sorticon-material
  {
     transform: rotate(180deg);
     transition: transform 0.2s ease-out;
     left: -1px;
 }
 .jqx-combobox-state-selected-material .jqx-icon-arrow-down-material{
     left:-1px;
 }
 .jqx-listbox-container {
     margin-top: 1px;
 }
 
 input[type="text"].jqx-input-material:-moz-placeholder, input[type="text"].jqx-widget-content-material:-moz-placeholder, input[type="textarea"].jqx-widget-content-material:-moz-placeholder, textarea.jqx-input-material:-moz-placeholder {
     color: #999999;
 }
 
 input[type="text"].jqx-input-material:-webkit-input-placeholder, input[type="text"].jqx-widget-content-material:-webkit-input-placeholder, input[type="textarea"].jqx-widget-content-material:-webkit-input-placeholder, textarea.jqx-input-material:-webkit-input-placeholder {
     color: #999999;
 }
 
 input[type="text"].jqx-input-material:-ms-input-placeholder, input[type="text"].jqx-widget-content-material:-ms-input-placeholder, input[type="textarea"].jqx-widget-content-material:-ms-input-placeholder, textarea.jqx-input-material:-ms-input-placeholder {
     color: #999999;
 }
 
 .jqx-combobox-content-focus-material, .jqx-combobox-state-focus-material, .jqx-fill-state-focus-material,
 .jqx-numberinput-focus-material {
     outline: none;
 }
 
 .jqx-combobox-multi-item-material {
     color: rgba(255, 255, 255, 1);
     color: var(--jqx-editors-combobox-multi-item-text-color-material);
     background: rgba(158, 168, 168, 1);
     background: var(--jqx-editors-combobox-multi-item-background-color-material);
     border-color: rgba(158, 168, 168, 1);
     border-color: var(--jqx-editors-combobox-multi-item-background-color-material);
 }
 
 .jqx-popup-material.jqx-fill-state-focus-material {
     outline: none;
     border-color: #E0E0E0 !important;
 }
 
 .jqx-datetimeinput-content, .jqx-datetimeinput-container {
     overflow: visible !important;
 }
 .jqx-text-area-material, .jqx-text-area-material > div {
     overflow:visible !important;
 }
 .jqx-text-area-element-material {
    box-sizing: border-box;
 }
 .jqx-pivotgrid-content-wrapper.jqx-fill-state-normal-material {
     border-color: rgba(227, 227, 227, 1);
     border-color: var(--jqx-border-color-material);
 }
 
 .jqx-widget-header-material.jqx-pivotgrid-content-wrapper {
     background-color: rgba(255, 255, 255, 1) !important;
     background-color: var(--jqx-pivotgrid-header-background-color-material) !important;
     color: rgba(0, 0, 0, 0.87) !important;
     color: var(--jqx-pivotgrid-header-text-color-material) !important;
 }
 
 .jqx-grid-cell-normal-material.jqx-pivotgrid-content-wrapper {
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-pivotgrid-cell-background-color-material);
     color: rgba(0, 0, 0, 0.6);
     color: var(--jqx-pivotgrid-cell-text-color-material);
 }
 
 .jqx-grid-cell-selected-material.jqx-pivotgrid-content-wrapper {
     color: rgba(158, 168, 168, 1) !important;
     color: var(--jqx-pivotgrid-selected-cell-text-color-material) !important;
     background-color: rgba(158, 168, 168, 0.2) !important;
     background-color: var(--jqx-pivotgrid-selected-cell-background-color-material) !important;
     border-color: rgba(158, 168, 168, 0.2) !important;
     border-color: var(--jqx-pivotgrid-selected-cell-background-color-material) !important;
 }
 
 .jqx-tabs-title-selected-top-material, .jqx-tabs-selection-tracker-top-material {
     border-color: transparent;
     -webkit-filter: none;
             filter: none;
     background: rgba(255, 255, 255, 1);
     background: var(--jqx-tabs-selected-tab-background-color-material);
     box-shadow: none;
 }
 .jqx-grid-cell-filter-row-material {
     background-color: #fafafa;
 }
 
 .jqx-tabs-title-material, .jqx-ribbon-item-material {
     color: rgba(0, 0, 0, 0.87);
     color: var(--jqx-tabs-tab-text-color-material);
 }
 .jqx-tabs-title-selected-bottom-material,
 .jqx-tabs-title-selected-top-material
  {
     color: rgba(158, 168, 168, 1);
     color: var(--jqx-tabs-selected-tab-text-color-material);
     font-weight:500;   
     padding-top:5px;
     padding-bottom:5px;
 }
 .jqx-tabs-title.jqx-fill-state-hover-material {
     border-color: transparent;
 }
 .jqx-ribbon-item-material {
     cursor: pointer;
 }
 .jqx-ribbon-item-selected-material {
     color: rgba(158, 168, 168, 1);
     color: var(--jqx-tabs-selected-tab-text-color-material);
     font-weight:500;
     border-color: transparent;
 }
 
 .jqx-ribbon-item-hover-material {
     background: transparent;
 }
 
 .jqx-ribbon-header-top-material {
     border-color: transparent;
     border-bottom-color: rgba(227, 227, 227, 1);
     border-bottom-color: var(--jqx-border-color-material);
 }
 
 .jqx-ribbon-header-bottom-material {
     border-color: transparent;
     border-top-color: rgba(227, 227, 227, 1);
     border-top-color: var(--jqx-border-color-material);
 }
 
 .jqx-ribbon-header-right-material {
     border-color: transparent;
     border-left-color:rgba(227, 227, 227, 1);
     border-left-color:var(--jqx-border-color-material);
 }
 
 .jqx-ribbon-header-left-material {
     border-color: transparent;
     border-right-color:rgba(227, 227, 227, 1);
     border-right-color:var(--jqx-border-color-material);
 }
 
 .jqx-tabs-title-selected-bottom-material, .jqx-tabs-selection-tracker-bottom-material {
     border-color: transparent;
     border-top: 1px solid #fff;
     -webkit-filter: none;
             filter: none;
     background: rgba(255, 255, 255, 1);
     background: var(--jqx-tabs-selected-tab-background-color-material);
     box-shadow: none;
 }
 
 .jqx-tabs-material, .jqx-ribbon-material {
     border-color: transparent;
 }
 
 .jqx-tabs-position-bottom .jqx-tabs-header-material {
     border-color: transparent;
 }
 .jqx-layout-material .jqx-tabs-header-material, .jqx-layout-material .jqx-ribbon-header-material {
     background: rgba(255, 255, 255, 1);
     background: var(--jqx-background-color-material);
     border-color: rgba(227, 227, 227, 1);
     border-color: var(--jqx-border-color-material);
 }
 .jqx-tabs-title-bottom {
     border-color: transparent;
 }
 .jqx-tabs-title-hover-top-material, .jqx-tabs-title-hover-bottom-material {
     box-shadow: none !important;
     color: rgba(255, 255, 255, 1);
     color: var(--jqx-tabs-hovered-tab-text-color-material);
     background: rgba(158, 168, 168, 1);
     background: var(--jqx-tabs-hovered-tab-background-color-material);
 }
 
 .jqx-tabs-header-material, .jqx-tabs-arrow-background-material {
     background-color: rgba(255, 255, 255, 1) !important;
     background-color: var(--jqx-tabs-header-background-color-material) !important;
     border-bottom: 1px solid rgba(224, 224, 224, 1);
     border-bottom: 1px solid var(--jqx-tabs-border-color-material);
 }
 
 .jqx-tabs-content-material {
     box-sizing: border-box;
     color: rgba(85, 85, 85, 1);
     color: var(--jqx-tabs-content-text-color-material);
     background-color: rgba(255, 255, 255, 1);
     background-color: var(--jqx-tabs-content-background-color-material);
     border: 1px solid rgba(224, 224, 224, 1);
     border: 1px solid var(--jqx-tabs-border-color-material);
     border-top-color: transparent;
     padding:5px;
 }
 .jqx-tabs-bar-material {
     position: absolute;
     bottom: 0;
     background: rgba(158, 168, 168, 1);
     background: var(--jqx-tabs-selected-tab-bottom-border-color-material);
     height: 2px;
     z-index:10;
     transition: .5s cubic-bezier(.35,0,.25,1);
 }
 .jqx-tabs-bar-material.vertical {
     width: 2px;
 }
 .jqx-tabs-position-bottom .jqx-tabs-bar-material {
     top: 0;
 }
 
 
 .jqx-layout-material {
     background-color: #cccccc;
 }
 
 .jqx-kanban-column-header-collapsed-material { /* ff3.6+ */ /* safari4+,chrome */ /* safari5.1+,chrome10+ */ /* opera 11.10+ */ /* ie10+ */
     background: linear-gradient(90deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* w3c */
 }
 
 
 .jqx-calendar-cell-material {
     border-radius: 50%;
     font-size:12px;
 }
 .jqx-calendar-cell-year-material,
 .jqx-calendar-cell-decade-material {
     border-radius: 25%;
 }
 
 .jqx-calendar-title-content-material {
     font-weight:bold;
 }
 
 .jqx-calendar-column-cell-material {
     color: rgba(0, 0, 0, 0.87);
     color: var(--jqx-editors-calendar-header-text-color-material);
     font-size:12px;
 }
 
 /* Ripple effect */
 .ripple {
     position: relative;
     transform: translate3d(0, 0, 0);
     overflow:hidden;
 }
 
 .ink {
     display: block;
     position: absolute;
     pointer-events: none;
     border-radius: 0%;
     transform: scaleX(0);
     background: rgba(0,119,190,0.5); 
     opacity: 0.25;
 }
 
 
 .outlined .ink, .flat .ink {
     background: rgba(0,119,190,0.5); 
     overflow:hidden;
 }
 
 .ink.animate {
     animation: ripple .7s ease;
     animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
 }
 .jqx-checkbox-material .ripple,
 .jqx-radiobutton-material .ripple
  {
     overflow:visible;
 }
 .jqx-checkbox-material .ink,
 .jqx-radiobutton-material .ink 
  {
     transform: scale(0); 
     background: rgba(158, 168, 168, 1); 
     background: var(--jqx-accent-color-material);
     border-radius: 50%;
 }
 .jqx-checkbox-material .ink.animate,
 .jqx-radiobutton-material .ink.animate
  {
     animation: checkRipple 0.3s ease;
     animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
 }
 .jqx-checkbox-material .ink.active,
 .jqx-radiobutton-material .ink.active
  {
     opacity: 0.2;
     transform: scale(2);
 }
 .jqx-checkbox-default-material.active .ink,
 .jqx-radiobutton-default-material.active .ink
  {
     opacity: 0.2;
     transform: scale(2);
 }
 /* Ripple effect */
 .buttonRipple {
   background-position: center;
   transition: background 0.8s;
 }
 .jqx-widget-material.buttonRipple:hover {
   background: rgba(26, 43, 106, 1) radial-gradient(circle, transparent 1%, rgba(26, 43, 106, 1)  1%) center/15000%;
   background: var(--jqx-button-default-hovered-state-background-color-material) radial-gradient(circle, transparent 1%, var(--jqx-button-default-hovered-state-background-color-material)  1%) center/15000%;
   border-color: rgba(26, 43, 106, 1);
   border-color: var(--jqx-button-default-hovered-state-background-color-material);
 }
 .jqx-widget-material.buttonRipple:active {
   background-color: var(--jqx-button-default-ripple-color-material);
   background-size: 100%;
   transition: background 0s;
 }
 .jqx-widget-material.buttonRipple:active:not(:hover) {
       color: rgba(255, 255, 255, 1);
       color: var(--jqx-background-color-material);
 }
 @keyframes ripple {
     100% {
         opacity: 0;
         transform: scale(5);
         animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
     }
 }
 @keyframes checkRipple {
     100% {
         opacity: 0.2;
         transform: scale(2);
         animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
     }
 }
 
 .jqx-editor-toolbar-button-material{
     border-color: #ddd;
     box-shadow: none !important;
     color: #333;
 }
 
 .jqx-widget-material #formWrap {
     color: #555 !important;    
 }
 
 .jqx-tabs-title-bootstrap, .jqx-tabs-title-bottom-bootstrap, .jqx-expander-header-bootstrap {
    color: rgba(0, 0, 0, 0.6) !important;
    color: var(--jqx-text-color-material) !important;
}

.jqx-tabs-title-selected-top-bootstrap, .jqx-tabs-selection-tracker-top-bootstrap {
    color: rgba(0, 0, 0, 0.87) !important;
    color: var(--jqx-header-text-color-material) !important;
}

.jqx-tabs-header-bootstrap{
    padding-bottom: 10px;
}

.jqx-tabs-title-selected-top-bootstrap, .jqx-tabs-selection-tracker-top-bootstrap, .jqx-tabs-title-bootstrap, .jqx-tabs-title-bottom-bootstrap, .jqx-expander-header-bootstrap {
    padding: 8px 20px 4px 20px;
    font-size: 15px;
}

.jqx-tabs-title-selected-top{
    background-color: #D9DCDC;
    padding-bottom: -25px !important;
}

.jqx-tabs-title{
    padding-bottom: 10px;
}

.jqx-tabs-titleWrapper{
    margin-top: 0 !important;
}

.jqx-numberinput-bootstrap > input{
    width: 90% !important;
}

input[type=password].jqx-input-bootstrap, 
input[type=password].jqx-widget-content-bootstrap, 
input[type=textarea].jqx-widget-content-bootstrap, 
textarea.jqx-input-bootstrap{
    padding-left:3px !important;
    padding-right:3px !important;
}
 
@charset "utf-8";
/* CSS Document */
@font-face {
    font-family: 'OpenSans';
    src: url(/static/media/OpenSans-Regular.629a55a7.ttf);
}

html {
	line-height: 1.3;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {display: block;}
audio, video {display: inline-block;}
a {
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
	outline: 0;
}
img {
	border-style: none;
	-ms-interpolation-mode: bicubic;
	image-rendering: optimizeQuality;
}
a[href], input[type=image], select, button, input[type=button], input[type=reset], input[type=submit] {cursor: pointer;}
button, input {overflow: visible;}
button, select {text-transform: none;}
button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
	border-style: none;
	padding: 0;
}
button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {outline: 1px dotted ButtonText;}
/* Login */
html {
    min-height: 100%;
	position: relative;
	overflow-x: hidden;
}
body {
	font-family: 'OpenSans' !important;
	font-size: 100%;
	overflow-x: hidden;
}

@media (min-width: 1200px){
	.container {
		max-width: 1500px;
	}
}

.flex-display{
	display: flex;
}

.mouseBlock, .mouseBlock > div, .mouseBlock > div > input{
	cursor: no-drop !important;
}

.form-control{
	font-size: 14px
}

input::-webkit-calendar-picker-indicator{
    display: none;
}

input[type=date]::-webkit-inner-spin-button, 
input[type=date]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.logoPrincipal{
	margin-top: -7%;
	max-height: 600px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}

.text-disabled {
	color: #d9d9d9 !important;
	cursor: not-allowed;
}

[role="tooltip"] {
    position: absolute;
    width: auto;
    max-width: 600px;
    height: auto;
    min-height: 25px;
    line-height: 1rem;
    font-size: 0.8rem;
    background-color: rgba(240, 240, 240, 0.9);
    color: #000;
    border: 1px solid rgb(168, 168, 168);
    border-radius: 5px;
    padding: 8px 12px;
    transform: translateX(-50%);

    opacity: 0;

    z-index: 1001;
}

[role="tooltip"].active-tooltip {
    opacity: 1;
    transition: opacity 0.2s;
}
