.jqx-grid {
    margin-bottom: 1rem !important;
}

.totalizers label.form-label {
    height: 40px;
    display: flex !important;
    flex-direction: column-reverse;
}

.buttonAndTooltip {
    display: flex;
    /* align-items: center; */
    flex-direction: column-reverse;
}

.buttonAndTooltip .jqx-loader {
    top: unset !important;
}

.tip, .option{
    padding-left: unset !important;
}

.tip {
    align-items: end !important;
}