body {
    background-color: #f0f0f0;
}

.login .form-control {
    background-color: #fffcc8 !important;
}

.jqx-button {
    background-color: #0079c1 !important;
}

.arcgis .jqx-button  {
    background-color: #fff !important;
    border-color: #0079c1 !important;
    color: #0079c1 !important;
}