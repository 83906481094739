.sub-header-reports {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ced4da;
    background: #e6e6e6;
    font-size: 13px;
    gap: 3px;
}

.sub-header-register {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ced4da;
    background: #e6e6e6;
    font-size: 13px;
    gap: 3px;
    margin-bottom: 16px;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 3px;
    background: #e6e6e6;
}

.text {
    font-weight: bold;
}