
.loading-container {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    overflow: hidden;
    outline: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999 !important;
}

.loading-container .loading-box {
    display: flex;
    position: relative;
    width: max-content;
    height: 136px;
    padding: 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:  rgba(255, 255, 255, 0.8);
    border-radius: 2rem;
}

.loading-container .loading-box div.jqx-widget.jqx-loader {
    display: flex !important;
    position: relative !important;
    margin: unset !important;
    border: none !important;
    background: none !important;
}

.loading-container .loading-box .loading-info {
    display: flex;
    position: relative;
    margin-top: 0.75rem;
    font-size: 15px;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}