.jqx-tabs {
    overflow: initial !important;
}

.jqx-tabs-headerWrapper {
    overflow: hidden !important;
    height: inherit !important;
}

/* .jqx-tabs-headerWrapper, .jqx-tabs-title-container {
    height: inherit !important;
}

.jqx-tabs-title-container {
    display: flex;
    width: 100% !important;
    flex-wrap: wrap;
    left: 0;
}

.jqx-tabs-title {
    float: none;
    position: initial;
} */

/* Completely hide visible=false tabs */
.esconder-abas-desativadas > .jqx-tabs-headerWrapper > .jqx-tabs-title-container > .jqx-tabs-title.jqx-tabs-title-disable {
    display: none;
}