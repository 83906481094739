.s-alert-box {
    min-width: 250px;
}

.s-alert-close::before, .s-alert-close::after {
    width: 2px;
}

/* ********************************* */
/* Hide arrows on input type numbers */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* ********************************* */