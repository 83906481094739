label {
    display: block !important;
}

.react-datepicker-popper {
    z-index: 20 !important;
}

.react-datepicker-wrapper, .react-datepicker__input-container input {
    width: 100%;
}

input.dateTimeField[readonly] {
    background-color: #e9ecef;
    color: #495057;
}
input.dateTimeField[disabled] {
    background-color: #e9ecef;
    color: rgb(73, 80, 87);
}
input.dateTimeField {
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
