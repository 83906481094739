.menuLateral {
    position: absolute;
    left: 0;
    z-index: 10000;
}

.nome{
    color : #082767;
    width: 100%;
    text-align: center;
    padding: 0px;
}
.cargo{
    color: #082767;
    width: 100%;
    text-align: center;
    padding: 0px;
    font-style: italic;
    font-size: small;
    margin-bottom: 10px;
}

.modal-title {
    color : #082767;
    width: 100%;
    text-align: center;
    padding: 0px;
}

.h4, h4 {
    font-size: 1.99rem;
}

.jqx-menu-item {
    width: 200px !important;
}

.jqx-menu-dropdown {
    width: 200px !important;
}

.caixa_lov {
    border: 1px solid #082767;
    margin: 0 2px;
    background: #F0F0F0;
}



