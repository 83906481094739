.legend {
    font-size: 13px;
    width: auto;
    margin-left: 7px;
    margin-bottom: .4rem;
    padding: 0 4px;
}

.fieldSet {
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: 5px 5px .65rem;
}