#navBar {
	height: 245px;
	margin-bottom: 25px;
}

#topo1 {
	background: #ffffff !important;
	width: 100%;
    height: 175px;
    left: 0;
    top: 0;
	position: absolute;
}
#topo2 {
	background: #e6e6e6;
	text-align: center;
	width: 100%;
    height: 70px;
	padding: 15px;
    left: 0;
    top: 175px;
	position: absolute;
	overflow: hidden;
}
@media screen and (max-width: 710px) {
    #topo2 h2 {
        font-size: 16px;
    }
}

#logo {
	background: url(../../assets/img/logo_geosgb_navbar.png) no-repeat left top;
	height: 205px;
	position: relative;
}
#logo p {
	color: #fff;
	font-size: 18px;
	padding: 50px 133px;
	position: relative;
}
#menuContainer {
	width: 200px;
}
#nomeUsuarioWrap{
	white-space: auto;
	display: flex;
	justify-content: center;
	font-size: .6rem;
	width: 200px;
}
#tituloWrap > h3 {
	margin-bottom: 0;
}


.navLinks{
	margin-top: 110px;
}

.link-white{
	color: #fff;
}

.link-white:hover{
	color: #fff;
}

#topo2 h2 {
	flex: 2;
}

#navBar-completo {
	width: 100%;
	position: static !important;
}

#topo1-completo {
	background: #ffffff !important;
	width: 100%;
	height: 55px;
	position: static !important;
}

#topo1-completo #logo {
	margin: 0 0 0 20%;
	max-height: 100%;
}

#topo1-completo p {
	margin: 0;
}
.fixed{
	position: fixed !important;
	top: 0;
}

#topo2-completo {
	display: flex;
	position: static;
	justify-content: space-between;
	width: 100%;
	min-height: 40px;
	padding: 0.4rem 1rem;
	margin-top: 5px;
	align-items: center;
	background: #e6e6e6;
	text-align: center;
}

#topo2-completo h2 {
	margin: 0 auto;
}

/* exibição inicial */
.logos-cabecalho {
	flex: 1;
	width: 100%;
	height: 100%;
	justify-content: space-around;
	align-items: center;
	display: flex;
}

.logo-container {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 20px;
	max-width: 27%;
	overflow: hidden;
}

.logo-container img {
	max-height: 150px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

/* 1 - Serviço Geológico do Brasil CPRM */
.logo-cprm img {
	max-height: 137px;
}

/* 2 - GEO SGB Sistemas Geocientíficos */
.logo-sgb {
	/* background: url(../../assets/img/logo_geosgb_navbar.png) no-repeat left top; */
	/* background: url(../../assets/img/logo_geosbg_sgih.png) no-repeat left top; */
	width: 220px;
		height: 205px;
		margin: 1% 20%;
		position: relative;
	max-width: 25%;
}

.logo-sgb p {
	color: #fff;
	font-size: 18px;
	font-size: 1.1vw;
	padding: 50px 0 50px 133px;
	position: relative;
	max-width: 25%;
}

/* 3 - ANA Agência Nacional de Águas e Saneamento Básico */
.logo-ana img {
	max-height: 137px;
}

/* 4 - Rede Hidrometeorológica Nacional */
.logo-rnh img {
	width: 280px;
	max-height: 280px;
}

/* 5 - SGIH Sistema de Gerenciamento de Informações Hidrométricas */
.logo-sgih {
	margin: 0;
}

.logo-sgih img {
	max-height: 55px;
}

#cprm{

}
#sgih{
	max-height: 70px;
}
#sgb{
	max-height: 90px;
}


.logo-cabecalho-img {
	margin-left: 30px !important;
	margin-right: 30px !important;

	/* max-width: 90%;
    max-height: 90%; */
}