.jqx-input-label-sgih {
    display: none !important;
}

.jqx-disable-select-sgih {
    color: #000 !important;
}

.jqx-dropdownlist-content-disabled-sgih {
    background-color: #e9ecef;
}

.jqx-dropdownlist-sgih {
    background-color: #fff;
    font-size: 14px;
    color: #6c757d;
    margin-right: -10px;
}

.jqx-fill-state-pressed-sgih {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.jqx-fill-state-disabled-sgih {
    color: #000;
    background-color: #e9ecef !important;
}

.jqx-dropdownlist-content-sgih span:before {
    content: "   ";
    white-space: pre;
}

.jqx-dropdownlist-content-disabled-sgih span:before {
    content: "   ";
    white-space: pre;
}