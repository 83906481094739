[role="tooltip"] {
    position: absolute;
    width: auto;
    max-width: 600px;
    height: auto;
    min-height: 25px;
    line-height: 1rem;
    font-size: 0.8rem;
    background-color: rgba(240, 240, 240, 0.9);
    color: #000;
    border: 1px solid rgb(168, 168, 168);
    border-radius: 5px;
    padding: 8px 12px;
    transform: translateX(-50%);

    opacity: 0;

    z-index: 1001;
}

[role="tooltip"].active-tooltip {
    opacity: 1;
    transition: opacity 0.2s;
}