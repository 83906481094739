

.file-grid__file {
    width: 185px;
    height: 215px;
    position: relative;

    padding: 5px;
    cursor: auto;
}

.file-grid__preview {
    width: 100%;
    height: 140px;
    border-radius: 3px;
    margin-bottom: 9px;
    display: flex;
    /*align-items: center;*/
    flex-direction: column;
    position: relative;
}

.file-grid__preview--uploading::before, .file-grid__preview--has-error::before {
    content: ' ';
    width: 100%;
    height: 140px;
    display: block;
    position: absolute;
    z-index: 1000;
    border-radius: 3px;
}

.file-grid__preview--uploading::before {
    background-color: rgba(0, 0, 0, .7);
}

.file-grid__preview--has-error::before {
    background-color: rgba(250, 0, 0, .7);
}

.file-grid__preview--has-error .file-grid__preview-icon {
    z-index: 2000;
}

.file-grid__preview--has-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-grid__preview-icon {
    font-size: 4rem;
}

.file-grid__preview--pdf {
    background-color: #e25149;
}

.file-grid__preview--word {
    background-color: #005996;
}

.file-grid__preview--file {
    background-color: #b3b3b3;
}

.file-grid__preview--zip {
    background-color: #967213;
}

.file-grid__preview i {
    color: white
}

.file-grid__preview img {
    object-fit: cover;
}

.file-grid__infoAndActions {
    display: flex;
}

.file-grid__info {
    flex-grow: 1;
    flex-shrink: 1;
    word-wrap: anywhere;
    overflow-wrap: anywhere;
}

.file-grid__actions {
    flex-grow: 0;
    flex-shrink: 0;
}

.file-grid__icon {
    padding: 3px;
    cursor: pointer;
}

.file-grid__icon--exclude::after {
    content: "❌";
}

.file-grid__icon--download {
    color: #3663ea;
}

.file-grid__file-name {
    margin-bottom: 4px;
}

.file-grid__file-size {
    font-size: 11px;
    color: grey;
}

.file-grid__file-error {
    font-size: 11px;
    color: crimson;
}

/* spinner */
.file-grid__spinner {
    display: inline-block;
    width: 80px;
    height: 80px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
}
.file-grid__spinner div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.file-grid__spinner div:nth-child(1) {
    left: 8px;
    animation: file-grid__spinner1 0.6s infinite;
}
.file-grid__spinner div:nth-child(2) {
    left: 8px;
    animation: file-grid__spinner2 0.6s infinite;
}
.file-grid__spinner div:nth-child(3) {
    left: 32px;
    animation: file-grid__spinner2 0.6s infinite;
}
.file-grid__spinner div:nth-child(4) {
    left: 56px;
    animation: file-grid__spinner3 0.6s infinite;
}
@keyframes file-grid__spinner1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes file-grid__spinner3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes file-grid__spinner2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
