.footer-logo-container { 
	display: -webkit-box;
	margin-left: 10%;
	width: 40%;
}
.logo-footer-img {
	max-width: 100%;
	width: auto;
    height: auto;
}

.footer-container {
	margin: 0 20px; 
	max-width: 25%;
	overflow: hidden;
}

.footer{
	display: flex;
	justify-content: center;
	max-height: 7%;
	background: #ffffff !important;
}
